import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import Popups, { ShowSuccess, ShowError } from './Modules/Popups';

import Account_Header from './Modules/Account_Header';
import Account_Navigation from './Modules/Account_Navigation';

import AccountSettings_ChangePassword from './Modals/AccountSettings_ChangePassword';
import AccountSettings_ChangeEmail from './Modals/AccountSettings_ChangeEmail';

import empty_image from "../images/empty.png"
import image_loading from "../images/loading2.gif"


const AccountSettings = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const [InvalidToken, SetInvalidToken] = React.useState(false);

    const [Id, SetId] = React.useState(0);
    const [Username, SetUsername] = React.useState("Username");
    const [Email, SetEmail] = React.useState("Email");
    const [Avatar, SetAvatar] = React.useState("Avatar");
    const [Balance, SetBalance] = React.useState(0);
    const [Hwid, SetHwid] = React.useState("-");
    const [Freezed, SetFreezed] = React.useState(0);
    const [FreezedLast, SetFreezedLast] = React.useState("");

    const [SubscriptionsParsed, SetSubscriptionsParsed] = React.useState(false);
    const [SubscriptionsArray, SetSubscriptionsArray] = React.useState([]);

    function decrypt(encryptedData) {
        const encryptedBytes = atob(encryptedData);
        const keyBytes = unescape(encodeURIComponent("MyEncryptionKey123"));

        const decryptedBytes = new Uint8Array(encryptedBytes.length);

        for (let i = 0; i < encryptedBytes.length; i++) {
            const encryptedByte = encryptedBytes.charCodeAt(i);
            const keyByte = keyBytes.charCodeAt(i % keyBytes.length);

            // Пропускаем символы, не соответствующие ASCII
            if (encryptedByte > 255 || keyByte > 255) {
                decryptedBytes[i] = encryptedByte;
            } else {
                decryptedBytes[i] = encryptedByte ^ keyByte;
            }
        }

        const decoder = new TextDecoder('utf-8');
        const decryptedString = decoder.decode(decryptedBytes);

        return JSON.parse(decryptedString);
    }
    function GetHwid() {
        var browser = navigator.hardwareConcurrency + "," + navigator.languages; //navigator.userAgent
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }

    useEffect(()=> {
        if (cookies.balance != null) SetBalance(cookies.balance);
        
        axios.post('https://api.0xcheats.net/Account/Info', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid() }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);

            if (json.success == "true") {
                SetId(json.id);
                SetUsername(json.username);
                SetEmail(json.email);
            
                if (json.avatar.length == 0)
                    SetAvatar("https://api.0xcheats.net/Images/Avatars/invalid.jpg");
                else
                    SetAvatar("https://api.0xcheats.net/Images/Avatars/" + json.avatar);
            
                SetBalance(json.balance);
                setCookie("balance", json.balance, { path: "/" });
            
                if (json.hwid.length > 0)
                    SetHwid(json.hwid);
            
                SetFreezed(json.freezed);
                SetFreezedLast(json.freezed_last);
            }
            else if (json.failed != null) {
                SetInvalidToken(true);
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            
        });
    }, [])
    if (InvalidToken) {
        removeCookie("token", { path: "/" });
        return <Navigate to="/auth" replace />;
    }


    function ResetHwid() {
        axios.post('https://api.0xcheats.net/Account/Settings/ResetHwid', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid() }))
        .then(function(response) {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);
            
            if (json.success != null)
            {
                if (json.hwid_reset == 1)
                    SetHwid("Не привязан");
                ShowSuccess(json.success);
            }
            else if (json.failed != null)
            {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            
        });
    }
    function FreezeToggle() {
        axios.post('https://api.0xcheats.net/Account/Settings/Freeze', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid() }))
        .then(function(response) {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);
            
            if (json.success != null)
            {
                SetFreezed(json.freezed);
                ShowSuccess(t(json.success));
            }
            else if (json.failed != null)
            {
                ShowError(t(json.failed));
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            
        });
    }
    function ActivateCode() {
        let code = document.getElementById('activation-code-value').value;

        //Проверяем поле
        if (code.length < 25) {
            ShowError(t("account_header_activate_error_1"));
            return;
        }

        document.getElementById('activate-code-button').style.display = "none";
        document.getElementById('activation-code-value').disabled = true;

        axios.post('https://api.0xcheats.net/Account/ActivateCode', qs.stringify({ 'token': cookies.token, 'code': code }))
        .then(function(response) {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);

            if (json.success == "true") {
                
                //Сбрасываем поле ввода
                document.getElementById('activation-code-value').value = "";
                
                ShowSuccess(t("account_header_activate_success_1"));
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            document.getElementById('activate-code-button').style.display = "flex";
            document.getElementById('activation-code-value').disabled = false;
        });
    }

    function HwidStatus() {
        if (Id == 0) {
            return <div className="loading"><br/><br/><br/></div>;
        }
        else
        {
            return (
            <>
                <div className="simple-truline fadeout">{t(Hwid)}</div>
                { Hwid != "-" ? <p className="btn black note-action fadeout" data-note-type="warning" onClick={ResetHwid}>{t('settings_hwid_reset')}</p> : "" }
            </>
            );
        }
    }
    function FreezeStatus() {
        if (Id == 0) {
            return <div className="loading"><br/><br/><br/></div>;
        }
        else
        {
            return (
            <>
                { Freezed ? <div className="simple-truline red fadeout">{t('settings_freeze_enabled')}</div> : <div className="simple-truline green fadeout">{t('settings_freeze_disabled')}</div> }
                <p className="btn black note-action fadeout" data-note-type="info" onClick={FreezeToggle}>{ Freezed ? t("settings_freeze_unfreeze") : t("settings_freeze_freeze") }</p>
            </>
            );
        }
    }
    function PasswordStatus() {
        if (Id == 0) {
            return <div className="loading"><br/><br/><br/></div>;
        }
        else
        {
            return (
                <AccountSettings_ChangePassword token={cookies.token}/>
            );
        }
    }
    function EmailStatus() {
        if (Id == 0) {
            return <div className="loading"><br/><br/><br/></div>;
        }
        else
        {
            return (
            <>
                <div className="simple-truline fadeout">{Email}</div>
                <AccountSettings_ChangeEmail token={cookies.token}/>
            </>
            );
        }
    }
    function CodeStatus() {
        if (Id == 0) {
            return <div className="loading"><br/><br/><br/></div>;
        }
        else
        {
            return (
            <>
                <input className="simple-truline fadeout" id="activation-code-value" type="text" placeholder={t('account_header_activate_2')} style={{width:'100%', marginRight:'0px'}}/>
                <p id="activate-code-button" class="btn black note-action fadeout" data-note-type="warning" onClick={() => ActivateCode()}>{t('account_header_activate')}</p>
            </>
            );
        }
    }


    return (
    <>
        <Popups/>
        <section id="settings" className="profile-page">
            <Account_Navigation active={"settings"}/>
            <div className="container">
                <div className="content">
                    <Account_Header username={Username} id={Id} avatar={Avatar} balance={Balance}/>
                    <div className="hl-reson"></div>
                    <div className="pre-title no-m fadeout"><i className="icon settings2"></i>{t('settings_title')}</div>
                    <div className="blocks fadeout">
                        <div className="block-wrapper">
                            <div className="block">
                                <div className="header">
                                    <p className="icon-wrapper"><i className="icon hwid"></i></p>{t('settings_hwid_title')}
                                </div>
                                <p className="subject">{t('settings_hwid_description')}</p>
                                <HwidStatus/>
                            </div>
                        </div>
                        <div className="block-wrapper">
                            <div className="block">
                                <div className="header">
                                    <p className="icon-wrapper"><i className="icon freeze"></i></p>{t('settings_freeze_title')}
                                </div>
                                <p className="subject">{t('settings_freeze_description')}</p>
                                <FreezeStatus/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br/>
            <div className="container">
                <div className="content">
                    <div className="hl-reson"></div>
                    <div className="pre-title no-m fadeout"><i className="icon settings2"></i>{t('settings_title_2')}</div>
                    <div className="blocks fadeout">
                        <div className="block-wrapper">
                            <div className="block">
                                <div className="header">
                                    <p className="icon-wrapper"><i className="icon security"></i></p>{t('settings_password_title')}
                                </div>
                                <p className="subject">{t('settings_password_description')}</p>
                                <PasswordStatus/>
                            </div>
                        </div>
                        <div className="block-wrapper">
                            <div className="block">
                                <div className="header">
                                    <p className="icon-wrapper"><i className="icon email"></i></p>{t('settings_email_title')}
                                </div>
                                <p className="subject">{t('settings_email_description')}</p>
                                <EmailStatus/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br/>
            <div className="container">
                <div className="content">
                    <div className="hl-reson"></div>
                    <div className="pre-title no-m fadeout"><i className="icon settings2"></i>{t('settings_title_3')}</div>
                    <div className="blocks fadeout">
                        <div className="block-wrapper">
                            <div className="block">
                                <div className="header">
                                    <p className="icon-wrapper"><i className="icon email"></i></p>{t('settings_code_title')}
                                </div>
                                <p className="subject">{t('settings_code_description')}</p>
                                <CodeStatus/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    );
};
export default AccountSettings;