import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useCookies } from 'react-cookie';

import axios from 'axios';
import qs from 'qs';

import {ShowError} from '../Modules/Popups';

import loading_image from '../../images/loading2.gif';


const AccountCoder_Upload = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);

    let freezedSoftware = true;

    function ParseVersions() {
        // axios.post('https://api.0xcheats.net/Account/Coder/Hack/Versions', qs.stringify({ 'token': props.token, 'identifier': props.identifier }))
        // .then(function(response)
        // {
        //     const data = JSON.stringify(response.data);
        //     const json = JSON.parse(data);
            
        //     if (json.success == "true")
        //     {
                 document.getElementById('body-loading').style.display = "none";
                 document.getElementById('body').style.display = "block";
                 document.getElementById('upload-body').style.display = "none";

        //         //Очищаем и обновляем список версий
        //         ClearVersionsList();

        //         if (json.uploaded != null) {
        //             const versionObject = document.createElement('div');
        //             versionObject.id = "current";
        //             versionObject.className = 'radio-box active';
        //             versionObject.addEventListener('click', () => SelectVersion("current"));

        //             const versionInfo = document.createElement('p');
        //             versionInfo.textContent = json.uploaded.version + ' | Загружено '+ json.uploaded.date +' в ' + json.uploaded.time;
        //             versionObject.appendChild(versionInfo);

        //             const versionSize = document.createElement('p');
        //             versionSize.textContent = '[' + (json.uploaded.size/(1024*1024)).toFixed(2) + 'MB]';
        //             versionInfo.appendChild(versionSize);

        //             const versionCheck = document.createElement('div');
        //             versionCheck.className = 'radio';
        //             versionObject.appendChild(versionCheck);

        //             const versionCheckSpan = document.createElement('span');
        //             versionCheck.appendChild(versionCheckSpan);

        //             document.getElementById('current-version').appendChild(versionObject);
        //         }

        //         json.versions.forEach(function(version) {
        //             const versionObject = document.createElement('div');
        //             versionObject.id = version.version;
        //             versionObject.className = 'radio-box';
        //             versionObject.addEventListener('click', () => SelectVersion(version.version));
        //             //versionObject.addEventListener('click', SelectVersion.bind(null, version.version));

        //             const versionInfo = document.createElement('p');
        //             versionInfo.textContent = version.version + ' | Загружено '+ version.date +' в ' + version.time;
        //             versionObject.appendChild(versionInfo);

        //             const versionSize = document.createElement('p');
        //             versionSize.textContent = '[' + (version.size/(1024*1024)).toFixed(2) + 'MB]';
        //             versionInfo.appendChild(versionSize);

        //             const versionCheck = document.createElement('div');
        //             versionCheck.className = 'radio';
        //             versionObject.appendChild(versionCheck);

        //             const versionCheckSpan = document.createElement('span');
        //             versionCheck.appendChild(versionCheckSpan);

        //             document.getElementById('versions').appendChild(versionObject);
        //         });
        //     }
        //     else if (json.failed !== null)
        //     {
        //         ShowError(json.failed);
        //     }
        // })
        // .catch(function(error)
        // {
        //     ShowError(error);
        // })
        // .finally(function()
        // {
            
        // });
    }

    function ClearVersionsList() {

        const current_version = document.getElementById('current-version');
        while (current_version.firstChild)
            current_version.removeChild(current_version.firstChild);

        const versions = document.getElementById('versions');
        while (versions.firstChild)
            versions.removeChild(versions.firstChild);
    }

    function ReleaseVersion() {
    
        const active = document.querySelector("#versions .active");
        if (active) {
            document.getElementById('body-loading').style.display = "block";
            document.getElementById('body').style.display = "none";

            axios.post('https://api.0xcheats.net/Account/Coder/Hack/Versions/Release', qs.stringify({ 'token': props.token, 'identifier': props.identifier, 'version': active.id }))
            .then(function(response)
            {
                const data = JSON.stringify(response.data);
                const json = JSON.parse(data);
                
                if (json.success == "true")
                {
                    //Обновляем список версий
                    ParseVersions();
                }
                else if (json.failed !== null)
                {
                    ShowError(json.failed);
                }
            })
            .catch(function(error)
            {
                ShowError(error);
            })
            .finally(function()
            {
                
            });
        }
        else {
            ShowError("Выбранная версия уже в релизе!");
        }
    };
    function DeleteVersion() {
    
        const active = document.querySelector("#versions .active");
        if (active) {
            document.getElementById('body-loading').style.display = "block";
            document.getElementById('body').style.display = "none";

            axios.post('https://api.0xcheats.net/Account/Coder/Hack/Versions/Delete', qs.stringify({ 'token': props.token, 'identifier': props.identifier, 'version': active.id }))
            .then(function(response)
            {
                const data = JSON.stringify(response.data);
                const json = JSON.parse(data);
                
                if (json.success == "true")
                {
                    //Обновляем список версий
                    ParseVersions();
                }
                else if (json.failed !== null)
                {
                    ShowError(json.failed);
                }
            })
            .catch(function(error)
            {
                ShowError(error);
            })
            .finally(function()
            {
                
            });
        }
        else {
            ShowError("Нельзя удалить активную версию!\nСначала её необходимо изменить.");
        }
    };
    function OtherFiles() {
        ShowError("Не работает, заебал");
    }

    const isHover = e => e.parentElement.querySelector(':hover') === e;
    function Close() {
        document.getElementById('modal-wrapper').classList.remove('show');
        document.getElementById('modal-wrapper').classList.remove('fadeout');
        document.getElementById('modal').classList.remove('show');
    }
    function CloseFull() {
        const hovered = isHover(document.getElementById('modal'));
        if (!hovered)
            Close();
    }
    function Show() {
        ReactDOM.render(<AccountCoder_Upload identifier={props.identifier} name={props.name}/>, document.getElementById('modals'));

        setTimeout(() => {
            document.getElementById('close-button').onclick = Close;
            document.getElementById('close-button').onclick = Close;

            document.getElementById('modal-wrapper').onclick = CloseFull;
            document.getElementById('modal-wrapper').classList.add('show');
            document.getElementById('modal-wrapper').classList.add('fadeout');
            document.getElementById('modal').classList.add('show');
            document.getElementById('body-loading').style.display = "block";
            document.getElementById('body').style.display = "none";
            document.getElementById('upload-body').style.display = "none";

            document.getElementById('current').classList.add('active');

            document.getElementById('freezed-software').onclick = () => {
                
                freezedSoftware = !freezedSoftware;

                if (freezedSoftware)
                    document.getElementById('freezed-software').classList = "radio-box active";
                else
                    document.getElementById('freezed-software').classList = "radio-box";

                document.getElementById('freezed-software').value = freezedSoftware;
            };
            document.getElementById('freezed-software').classList.add('active');
        }, 200);
    }

    return (
    <>
        <div id="modal-wrapper" className="modal-wrapper" onClick={CloseFull}>
            <div id="modal" className="modal cash-up">
                <div className="header">
                    <img className="avatar" alt="" src={"https://api.0xcheats.net/Images/Games/" + props.identifier +"/logo.jpg"}/>
                    <p className="name">{props.name}</p>
                    <span>Управление <br/> софтом</span>
                </div>

                <div id="body-loading" className="body">
                    <p className="pre-title fadeout">Параметры</p>
                    <div className="radio-boxes fadeout">
                        <div className="radio-box loading">
                            <div className="radio"><span></span></div>
                        </div>
                    </div>
                </div>

                <div id="body" className="body" style={{display:'none'}}>
                    <p className="pre-title fadeout">Параметры</p>
                    <div id="current-version" className="radio-boxes fadeout">
                        <div id="freezed-software" className="radio-box active">
                            <p>Продукт активен или заморожен</p>
                            <div className="radio"><span></span></div>
                        </div>
                    </div>
                    <div className="go-up fadeout">
                        <p id="save-changes" className="btn black clickable" style={{background:'#3DB37A', margin:'5px', width:'100%'}}>Сохранить изменения</p>
                        <p id="close-button" className="btn black clickable" style={{background:'#000', margin:'5px', width:'100%'}}>Назад</p>
                    </div>
                </div>

            </div>
        </div>

        <div className="status-view inactive" onClick={() => Show()}><i className="icon settings"></i></div>
    </>
    );
};
export default AccountCoder_Upload;