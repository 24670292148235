import React, { useEffect, useRef, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Turnstile } from '@marsidev/react-turnstile';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import Header from './Header';
import Footer from './Footer';

import Popups, {ShowError} from './Modules/Popups';

import loading_image from '../images/loading2.gif';
import empty_image from "../images/empty.png"
import user_image from "../images/user.jpg"


const Auth = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const captcha_ref = useRef(null);
    const [key, setKey] = useState(123);

    //Сбрасываем авторизацию
    //removeCookie("token", { path: "/" });
    removeCookie("is_coder", { path: "/" });
    removeCookie("is_partner", { path: "/" });
    removeCookie("is_reseller", { path: "/" });
    removeCookie("is_supporter", { path: "/" });

    function GetLang() {
        const language = navigator.language || navigator.userLanguage;
        //console.log(language); // Выведет язык в формате "en-US", "ru-RU", "zh-CN" и т.д.
        return language.split('-')[0];
    }
    useEffect(()=> {
        setTimeout(() => {
            if (cookies.lang == null) {
                let lang = GetLang();
                changeLanguage(lang);
                setCookie("lang", lang, { path: "/" });
            }
            else {
                changeLanguage(cookies.lang);
            }
        }, 100);
    }, [])

    const [Redirect, SetRedirect] = useState(false);
    if (Redirect) {
        return <Navigate to={"/account"} replace />;
    }

    function GetHwid() {
        var browser = navigator.hardwareConcurrency + "," + navigator.languages; //navigator.userAgent
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }

    function StartAuth() {
        let username = document.getElementById("username").value;
        let password = document.getElementById("password").value;
        let captcha = captcha_ref.current?.getResponse();

        document.getElementById("auth-button1").style.display = 'none';
        document.getElementById("auth-button2").style.display = 'flex';

        axios.post('https://api.0xcheats.net/Account/Auth', qs.stringify({ 'username': username, 'password': password, 'ahwid': GetHwid(), 'captcha': captcha }))
        .then(function(response) {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);
            
            if (json.success == "true") {
                setCookie("token", json.token, { path: "/" });
                SetRedirect(true);
            }
            else if (json.success == "request_code") {
                document.getElementById("user-email").innerHTML = json.token;
                document.getElementById("auth-main").style.display = 'none';
                document.getElementById("auth-2fa").style.display = 'block';
            }
            else if (json.failed != null) {
                ShowError(json.failed);

                //Перезагружаем капчу
                setKey(Math.random());
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            document.getElementById("auth-button1").style.display = 'flex';
            document.getElementById("auth-button2").style.display = 'none';
        });
    }
    function StartAuth2() {
        let username = document.getElementById("username").value;
        let password = document.getElementById("password").value;
        let code = document.getElementById("code").value;
        let captcha = captcha_ref.current?.getResponse();

        document.getElementById("auth-button1").style.display = 'none';
        document.getElementById("auth-button2").style.display = 'flex';

        axios.post('https://api.0xcheats.net/Account/Auth', qs.stringify({ 'username': username, 'password': password, 'code': code, 'ahwid': GetHwid(), 'captcha': captcha }))
        .then(function(response) {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);
            
            if (json.success == "true") {
                setCookie("token", json.token, { path: "/" });
                SetRedirect(true);
            }
            else if (json.success == "request_code") {
                document.getElementById("auth-main").style.display = 'none';
                document.getElementById("auth-2fa").style.display = 'block';
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            document.getElementById("auth-button1").style.display = 'flex';
            document.getElementById("auth-button2").style.display = 'none';
        });
    }

    return (
    <>
        <Popups/>

        <Header/>

        <section id="settings" className="profile-page fadeout">
            <div className="container">
                <div className="content">
                    <div className="modal2-wrapper">

                        <div id="auth-main" className="modal cash-up show">
                            <div className="header">
                                <img className="avatar" alt="" src={user_image} draggable="false"/>
                                <p className="name">{t('auth_title')}</p>
                            </div>

                            <div className="body">
                                <p className="pre-title fadeout" style={{marginBottom: '15px'}}>{t('auth_username')}</p>
                                <input id="username" className="inputtext" type="text" maxLength="24" placeholder="Username"/>
                                <p className="pre-title fadeout" style={{marginTop: '10px', marginBottom: '15px'}}>{t('auth_password')}</p>
                                <input id="password" className="inputtext" type="password" maxLength="32" placeholder="*************"/>

                                <center>
                                    <br/>
                                    <Turnstile ref={captcha_ref} siteKey="0x4AAAAAAA9t0jFDTrvHLD0W" key={key}/>
                                </center>

                                <br/>
                                <div className="go-up fadeout">
                                    <p id="auth-button1" className="btn clickable" onClick={StartAuth}><span>{t('auth_enter')}</span></p>
                                    <p id="auth-button2" className="btn" style={{display:'none'}}><span>...</span></p>

                                    <Link to="/register" className="btn clickable" style={{marginLeft: 'auto', marginRight: '0px'}}><span>{t('auth_create')}</span></Link>
                                </div>

                                <br/><br/>
                                <center>
                                    <Link to="forgot">{t('auth_forgot')}</Link>
                                </center>
                            </div>
                        </div>

                        <div id="auth-2fa" className="modal cash-up show" style={{display: "none"}}>
                            <div className="header">
                                <img className="avatar" alt="" src={user_image} draggable="false"/>
                                <p className="name">{t('auth_twofa')}</p>
                            </div>
                            
                            <div className="body">
                                <p className="pre-title fadeout" style={{marginBottom: '15px'}}>{t('auth_twofa_email_1')} <b id="user-email" style={{padding: '5px'}}>***sec@gmail.com</b> {t('auth_twofa_email_2')}</p>
                                <input id="code" className="inputtext" type="text" maxLength="24" placeholder="Введите код с письма"/>
                                
                                <br/><br/>
                                <div className="go-up fadeout">
                                    <p id="auth-button1" className="btn clickable" onClick={StartAuth2}><span>{t('auth_twofa_continue')}</span></p>
                                    <p id="auth-button2" className="btn" style={{display: "none"}}><span><img src={loading_image} width='168'/></span></p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <br/><br/>
                </div>
            </div>
        </section>
        
        {/*<Footer/>*/}
    </>
    );
};
export default Auth;