import React, { useEffect } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import AccountCoder_Upload from './Modals/AccountCoder_Upload';
import AccountCoder_Manage from './Modals/AccountCoder_Manage';

import Popups, {ShowError} from './Modules/Popups';
import AccountCoder_Week from './Modules/AccountCoder_Week';
import AccountCoder_Month from './Modules/AccountCoder_Month';
import AccountCoder_Stats from './Modules/AccountCoder_Stats';

import Account_Header from './Modules/Account_Header';
import Account_Navigation from './Modules/Account_Navigation';

import empty_image from "../images/empty.png"


const AccountScripts = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const [InvalidToken, SetInvalidToken] = React.useState(false);

    const [Id, SetId] = React.useState(0);
    const [Username, SetUsername] = React.useState("Username");
    const [Avatar, SetAvatar] = React.useState("Avatar");
    const [Balance, SetBalance] = React.useState(0);

    const [ScriptsParsed, SetScriptsParsed] = React.useState(false);
    const [ScriptsArray, SetScriptsArray] = React.useState([]);

    let { identifier } = useParams();

    function decrypt(encryptedData) {
        const encryptedBytes = atob(encryptedData);
        const keyBytes = unescape(encodeURIComponent("MyEncryptionKey123"));

        const decryptedBytes = new Uint8Array(encryptedBytes.length);

        for (let i = 0; i < encryptedBytes.length; i++) {
            const encryptedByte = encryptedBytes.charCodeAt(i);
            const keyByte = keyBytes.charCodeAt(i % keyBytes.length);

            // Пропускаем символы, не соответствующие ASCII
            if (encryptedByte > 255 || keyByte > 255) {
                decryptedBytes[i] = encryptedByte;
            } else {
                decryptedBytes[i] = encryptedByte ^ keyByte;
            }
        }

        const decoder = new TextDecoder('utf-8');
        const decryptedString = decoder.decode(decryptedBytes);

        return JSON.parse(decryptedString);
    }
    function GetHwid() {
        var browser = navigator.hardwareConcurrency + "," + navigator.languages; //navigator.userAgent
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }

    useEffect(()=> {
        axios.post('https://api.0xcheats.net/Account/Info', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid() }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);

            if (json.success == "true") {
                //Аккаунт
                SetId(json.id);
                SetUsername(json.username);

                if (json.avatar.length == 0)
                    SetAvatar("https://api.0xcheats.net/Images/Avatars/invalid.jpg");
                else
                    SetAvatar("https://api.0xcheats.net/Images/Avatars/" + json.avatar);

                SetBalance(json.balance);
            }
            else if (json.failed != null) {
                SetInvalidToken(true);
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {

        });

        axios.post('https://api.0xcheats.net/Scripts', qs.stringify({ 'identifier': identifier }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);
            
            if (json.success == "true")
            {
                SetScriptsParsed(true);
                SetScriptsArray(json.scripts);
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            
        });

    }, [])

    if (InvalidToken) {
        removeCookie("token", { path: "/" });
        return <Navigate to="/auth" replace />;
    }


    function Scripts() {
        if (ScriptsParsed) {
            return ScriptsArray.map((script) =>
                <div className="product-wrapper free" data-sort="free" key={script.hash}>
                    <div className="product">
                        <a href=""><img src="https://api.0xcheats.net/Images/Avatars/Invalid" alt=""/></a>
                        <div className="info">
                            <a href="" className="name">{script.title}</a>
                            <div className="hl"></div>
                            <div className="price">
                                <a href="" className="btn black">Бесплатно</a>
                            </div>
                        </div>
                    </div>
                </div>

            // <div className="product-wrapper" key={hack.identifier}>
            //     <div className="product">
            //         <img src={"https://api.0xcheats.net/Images/Games/" + hack.identifier +"/logo.jpg"} alt="" draggable="false"/>
            //         <div className="info">
            //             <div className="text">
            //                 <span className="status-text active">Ставка: {hack.percents}%</span>
            //                 <p className="name">{hack.name}</p>
            //             </div>
            //             { hack.can_manage == 1 ? <AccountCoder_Manage token={cookies.token} identifier={hack.identifier} name={hack.name}/> : "" }
            //             { hack.can_upload == 1 ? <AccountCoder_Upload token={cookies.token} identifier={hack.identifier} name={hack.name}/> : "" }
            //         </div>
            //     </div>
            // </div>
            );
        }
        else {
            return (
            <>
                <div className="product-wrapper">
                    <div className="product loading"></div>
                </div>

                <div className="product-wrapper">
                    <div className="product loading"></div>
                </div>

                <div className="product-wrapper">
                    <div className="product loading"></div>
                </div>
            </>
            );
        }
    }

    if (Id == 0) {
        return (
        <>
            <Popups/>
            <div className="mobile-menu-overlay">
                <div className="input activate-key"></div>
                <div className="payment-block"></div>
            </div>

            <section id="history" className="history-product profile-page">
                <Account_Navigation active={""}/>
                <div className="container">
                    <div className="content">
                        <Account_Header id={0}/>
                        <div className="hl-reson"></div>
                        <div className="sort-block pre-title fadeout">
                            <div className="title-m-verse">
                                <i className="icon products"></i>
                                <span className="subject-title">Список скриптов</span>
                                <p className="btn sort gray active" data-sort="free">Бесплатные</p>
                                <p className="btn sort gray" data-sort="payment">Платные</p>
                                <div className="sort-more">
                                    <p className="selected-sort">
                                        <span>По популярности</span>
                                        <i className="icon sort-items"></i>
                                    </p>
                                </div>
                            </div>
                            <div>
                                <p className="btn sort gray" data-sort="history">Мои покупки</p>
                                <p className="btn sort gray" data-sort="scripts">Мои скрипты</p>
                            </div>
                        </div>
                        <div className="history-buy max-piffer">

                        </div>

                    </div>
                </div>
            </section>
        </>
        );
    }
    else {
        return (
        <>
            <Popups/>
            <div className="mobile-menu-overlay">
                <div className="input activate-key">
                    <p className="btn black">Активировать</p>
                    <input type="text" placeholder="Введите код активации"/>
                </div>
                <div className="payment-block">
                    <div className="balance">
                        <a href="" className="btn black go-up-balance">Пополнить</a>
                        <span>50 000 <b>руб</b></span>
                    </div>
                    <div className="pay"><i className="icon payment"></i></div>
                </div>
            </div>

            <section id="history" className="history-product profile-page">
                <Account_Navigation active={""}/>
                <div className="container">
                    <div className="content">
                        <Account_Header username={Username} id={Id} avatar={Avatar} balance={Balance}/>
                        <div className="hl-reson"></div>
                        <div className="sort-block pre-title">
                            <div className="title-m-verse">
                                <i className="icon products"></i>
                                <span className="subject-title">Список скриптов</span>
                                <p className="btn sort gray active" data-sort="free">Бесплатные</p>
                                <p className="btn sort gray" data-sort="payment">Платные</p>
                                <div className="sort-more">
                                    <p className="selected-sort">
                                        <span>По популярности</span>
                                        <i className="icon sort-items"></i>
                                    </p>
                                    <div className="sort-dropdown">
                                        <p className="drop active" data-id="0">По популярности</p>
                                        <p className="drop" data-id="1">По рейтингу</p>
                                        <p className="drop" data-id="2">Сначала дешёвые</p>
                                        <p className="drop" data-id="3">Сначала дорогие</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p className="btn sort gray" data-sort="history">Мои покупки</p>
                                <p className="btn sort gray" data-sort="scripts">Мои скрипты</p>
                            </div>
                        </div>
                        <div className="history-buy max-piffer fadeout">
                            <Scripts/>

                            {/*<div className="product-wrapper free" data-sort="free">
                                <div className="product">
                                    <a href=""><img src="https://phonoteka.org/uploads/posts/2022-06/1654144417_33-phonoteka-org-p-gachimuchi-oboi-krasivo-37.jpg" alt=""/></a>
                                    <div className="info">
                                        <a href="" className="name">АДМИНСКИЙ ПОЛЁТ [GTA5RP]</a>
                                        <div className="hl"></div>
                                        <div className="price">
                                            <a href="" className="btn black">Бесплатно</a>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                            {/*<div className="product-wrapper" data-sort="payment" data-price="300">
                                <div className="product">
                                    <a href=""><img src="https://phonoteka.org/uploads/posts/2022-06/1654144417_33-phonoteka-org-p-gachimuchi-oboi-krasivo-37.jpg" alt=""/></a>
                                    <div className="info">
                                        <a href="" className="name">ШАМПУНЬ ЖУМАЙСЫНБА</a>
                                        <div className="hl"></div>
                                        <div className="price">
                                            <span>Стоимость <b>300 руб</b></span>
                                            <a href="" className="btn black">Купить</a>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                            {/*<div className="product-wrapper free" data-sort="history">
                                <div className="product">
                                    <a href=""><img src="https://c.tenor.com/WOhCfmhc0OQAAAAd/billy.gif" alt=""/></a>
                                    <div className="info">
                                        <a href="" className="name">АНИГИЛЯТОРНАЯ ПУШКА</a>
                                        <div className="hl"></div>
                                        <div className="price">
                                            <a href="" className="btn black">Скачать</a>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                            {/*<div className="product-wrapper free" data-sort="scripts">
                                <div className="product">
                                    <a href=""><img src="https://i.gifer.com/Qk8o.gif" alt=""/></a>
                                    <div className="info">
                                        <a href="" className="name">БЕШЕННЫЙ И СОВРЕМЕННЫЙ УБИВАТОР</a>
                                        <div className="hl"></div>
                                        <div className="price">
                                            <a href="" className="btn black">Изменить</a>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}

                        </div>
                    </div>
                </div>
            </section>
        </>
        );
    }
};
export default AccountScripts;