import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import axios from 'axios';
import qs from 'qs';

import image_logo from '../../images/logo.png';

import {decode as base64_decode, encode as base64_encode} from 'base-64';

const Account_Navigation = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);

    const [IsLogout, SetLogout] = React.useState(false);
    const [IsCoder, SetIsCoder] = React.useState(false);
    const [IsPartner, SetIsPartner] = React.useState(false);
    const [IsReseller, SetIsReseller] = React.useState(false);
    const [IsSupporter, SetIsSupporter] = React.useState(false);

    //Доступ к страницам
    useEffect(()=> {
        if (cookies.is_coder != null) SetIsCoder(true);
        if (cookies.is_partner != null) SetIsPartner(true);
        if (cookies.is_reseller != null) SetIsReseller(true);
        if (cookies.is_supporter != null) SetIsSupporter(true);
    }, [])

    //setTimeout(() => {
    //    alert(1);
    //    if (cookies.is_coder != null) SetIsCoder(true);
    //    if (cookies.is_reseller != null) SetIsReseller(true);
    //}, 1000);

    function Logout() {
        SetLogout(true);
    }
    if (IsLogout) {
        removeCookie("token", { path: "/" });
        removeCookie("is_coder", { path: "/" });
        removeCookie("is_partner", { path: "/" });
        removeCookie("is_reseller", { path: "/" });
        removeCookie("is_supporter", { path: "/" });
        return <Navigate to="/" replace />;
    }

    return (
    <div className="navigation">
        <Link to="/" className="logo"><img src={image_logo} alt=""/></Link>
        <div className="hl"></div>

        <Link to="/account" className={ props.active == "subscriptions" ? "nav-link active" : "nav-link" }><i className="icon products"></i></Link>
        
        { IsCoder ? <Link to="/account/coder" className={ props.active == "coder" ? "nav-link active" : "nav-link" }><i className="icon dev"></i></Link> : "" }
        { IsPartner ? <Link to="/account/partner" className={ props.active == "partner" ? "nav-link active" : "nav-link" }><i className="icon partner"></i></Link> : "" }
        { IsReseller ? <Link to="/account/reseller" className={ props.active == "reseller" ? "nav-link active" : "nav-link" }><i className="icon reseller"></i></Link> : "" }

        <Link to="/account/settings" className={ props.active == "settings" ? "nav-link active" : "nav-link" }><i className="icon settings"></i></Link>

        { IsSupporter ? <Link to="/account/supporter" className={ props.active == "help" ? "nav-link active" : "nav-link" }><i className="icon support"></i></Link> : <Link to="/account/help" className={ props.active == "help" ? "nav-link active" : "nav-link" }><i className="icon support"></i></Link> }

        <Link to="" className="nav-link logout" onClick={Logout}><i className="icon logout"></i></Link>
    </div>
    );
};
export default Account_Navigation;