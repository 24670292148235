import React, { useEffect, PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { curveCardinal } from 'd3-shape';
import { useCookies } from 'react-cookie';

import axios from 'axios';
import qs from 'qs';

import {ShowError} from '../Modules/Popups';


const AccountCoder_Week = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const [Data, SetData] = React.useState(null);

    function GetHwid() {
        var browser = navigator.hardwareConcurrency + "," + navigator.languages; //navigator.userAgent
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }

    useEffect(()=> {
        axios.post('https://api.0xcheats.net/Account/Partner/Stats/Week', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid() }))
        .then(function(response)
        {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);

            if (json.success == "true")
            {
                const newData = [
                {
                  name: 'Пн.',
                  Текущая: 0,
                  Прошлая: 0,
                },
                {
                  name: 'Вт.',
                  Текущая: 0,
                  Прошлая: 0,
                },
                {
                  name: 'Ср.',
                  Текущая: 0,
                  Прошлая: 0,
                },
                {
                  name: 'Чт.',
                  Текущая: 0,
                  Прошлая: 0,
                },
                {
                  name: 'Пт.',
                  Текущая: 0,
                  Прошлая: 0,
                },
                {
                  name: 'Сб.',
                  Текущая: 0,
                  Прошлая: 0,
                },
                {
                  name: 'Вс.',
                  Текущая: 0,
                  Прошлая: 0,
                }
              ];

                //Текущая неделя
                const current = JSON.parse(JSON.stringify(json.current));
                current.forEach((op) => {
                  if (op.dayOfWeek == "0")
                      newData[6]['Текущая'] = Number(op.totalAmount);
                  else
                      newData[Number(op.dayOfWeek)-1]['Текущая'] = Number(op.totalAmount);
                });

                //Прошлая неделя
                const past = JSON.parse(JSON.stringify(json.past));
                past.forEach((op) => {
                  if (op.dayOfWeek == "0")
                      newData[6]['Прошлая'] = Number(op.totalAmount);
                  else
                      newData[Number(op.dayOfWeek)-1]['Прошлая'] = Number(op.totalAmount);
                });

                SetData(newData);
            }
            else if (json.failed != null)
            {
                ShowError(json.failed);
            }
        })
        .catch(function(error)
        {
            ShowError(error);
        })
        .finally(function()
        {
            
        });
    }, [])


    const cardinal = curveCardinal.tension(0.2);

    return (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={400}
            height={400}
            data={Data}
            margin={{top: 10, right: 30, left: 0, bottom: 45}}
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="name"/>
            <YAxis/>
            <Tooltip/>
            <Area type={cardinal} dataKey="Прошлая" stroke="#ffc658" fill="#ffc658" fillOpacity={0.5}/>
            <Area type="monotone" dataKey="Текущая" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.5}/>
          </AreaChart>
        </ResponsiveContainer>
    );
};
export default AccountCoder_Week;