import React, { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import ModalImage from 'react-modal-image';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import Header from './Header';
import Footer from './Footer';

import Popups, {ShowError} from './Modules/Popups';

import loading_image from '../images/loading2.gif';
import empty_image from "../images/empty.png"

const Game = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const [InvalidToken, SetInvalidToken] = React.useState(false);

    const [Identifier, SetIdentifier] = React.useState(null);
    const [Name, SetName] = React.useState("");
    const [Description, SetDescription] = React.useState("");
    const [ReleaseDate, SetReleaseDate] = React.useState("");
    const [UpdateDate, SetUpdateDate] = React.useState("");
    const [Status, SetStatus] = React.useState("");
    const [TariffsArray, SetTariffsArray] = React.useState([]);
    const [ScreenshotsArray, SetScreenshotsArray] = React.useState([]);
    const [VideosArray, SetVideosArray] = React.useState([]);
    const [AnticheatsArray, SetAnticheatsArray] = React.useState([]);
    const [RequirementsArray, SetRequirementsArray] = React.useState([]);
    const [FeaturesArray, SetFeaturesArray] = React.useState([]);

    const [Once, SetOnce] = React.useState(false);

    let { identifier } = useParams();

    function decrypt(encryptedData) {
        const encryptedBytes = atob(encryptedData);
        const keyBytes = unescape(encodeURIComponent("MyEncryptionKey123"));

        const decryptedBytes = new Uint8Array(encryptedBytes.length);

        for (let i = 0; i < encryptedBytes.length; i++) {
            const encryptedByte = encryptedBytes.charCodeAt(i);
            const keyByte = keyBytes.charCodeAt(i % keyBytes.length);

            // Пропускаем символы, не соответствующие ASCII
            if (encryptedByte > 255 || keyByte > 255) {
                decryptedBytes[i] = encryptedByte;
            } else {
                decryptedBytes[i] = encryptedByte ^ keyByte;
            }
        }

        const decoder = new TextDecoder('utf-8');
        const decryptedString = decoder.decode(decryptedBytes);

        return JSON.parse(decryptedString);
    }
    function GetHwid() {
        var browser = navigator.hardwareConcurrency + "," + navigator.languages; //navigator.userAgent
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }

    useEffect(()=> {
        document.getElementById("_games").className = "active";

        axios.post('https://api.0xcheats.net/Account/Info', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid() }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);
            
            if (json.success == "true") {
                
            }
            else if (json.failed != null) {
                SetInvalidToken(true);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {

        });

        axios.post('https://api.0xcheats.net/Game', qs.stringify({ 'identifier': identifier, 'lang': cookies.lang }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);
            
            if (json.success == "true")
            {
                SetIdentifier(json.identifier);
                SetName(json.name);
                SetDescription(json.description);
                SetReleaseDate(json.release_date);
                SetUpdateDate(json.update_date);
                SetStatus(json.status);
                SetTariffsArray(json.tariffs);
                SetScreenshotsArray(json.screenshots);
                SetVideosArray(json.videos);
                SetAnticheatsArray(json.anticheats);
                SetRequirementsArray(json.requirements);
                SetFeaturesArray(json.features);
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            
        });
    }, [])


    let currentHours = 0;
    let currentPrice = 0;
    let currentPriceUSD = 0;
    function ClickTariff(hours, price, price_usd) {

        //Скрываем старый тариф
        if (currentHours != 0) {
            document.getElementById("tariff-" + currentHours).classList.remove("active");
        }

        //Отображаем новый тариф
        currentHours = hours;
        currentPrice = price;
        currentPriceUSD = price_usd;
        document.getElementById("tariff-" + hours).classList.add("active");

        //Если тариф выбран - отображаем кнопку покупки
        if (currentHours != 0) {
            document.getElementById("buy-button-price").innerText = price + " ₽";
            document.getElementById("buy-button").classList.remove("hide");

            document.getElementById("buy-button-price-usd").innerText = price_usd + " USDT";
            document.getElementById("buy-button-usd").classList.remove("hide");
        }
    }

    function ClickPayment() {
        if (currentPrice <= 0 || currentHours <= 0) {
            ShowError("Сначала нужно выбрать тариф!");
            return;
        }

        //Скрываем кнопку
        document.getElementById("buy-button").style.display = 'none';
        document.getElementById("buy-button2").style.display = 'flex';

        axios.post('https://api.0xcheats.net/Games/Buy', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid(), 'currency':"rub", 'identifier': identifier, 'hours': currentHours }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);
            
            if (json.success == "true") {
                window.open(json.url, "_blank");
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            //Отображаем кнопку
            document.getElementById("buy-button").style.display = 'flex';
            document.getElementById("buy-button2").style.display = 'none';
        });
    }
    function ClickPaymentUSD() {
        if (currentPrice <= 0 || currentHours <= 0) {
            ShowError("Сначала нужно выбрать тариф!");
            return;
        }

        //Скрываем кнопку
        document.getElementById("buy-button-usd").style.display = 'none';
        document.getElementById("buy-button-usd2").style.display = 'flex';

        axios.post('https://api.0xcheats.net/Games/Buy', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid(), 'currency':"usd", 'identifier': identifier, 'hours': currentHours }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);
            
            if (json.success == "true") {
                window.open(json.url, "_blank");
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            //Отображаем кнопку
            document.getElementById("buy-button-usd").style.display = 'flex';
            document.getElementById("buy-button-usd2").style.display = 'none';
        });
    }

    function FactPrice(amount, discount) {
        return amount - (amount/100*discount);
    }
    function DiscountBlock(amount, discount) {
        if (discount > 0)
            return <del style={{color:'#a3a3a3', fontSize:'15px', paddingRight:'8px'}}>{amount} ₽</del>;
    }

    function Tariffs() {

        if (TariffsArray.length == 0 || Status == -1 || Status == 2) {
            return (
                <a className="btn black clickable" style={{'background':'#000'}}>{t('game_unavailable')}</a>
            );
        }

        return TariffsArray.map((tariff) =>
            tariff.hours == 1000000 ? (<div id={"tariff-" + tariff.hours} key={tariff.hours} className="radio" onClick={() => ClickTariff(tariff.hours, FactPrice(tariff.amount, tariff.discount), FactPrice(tariff.amount_usd, tariff.discount))}><span className="circle"><span></span></span>{t('game_lifetime')}<p className="price">{DiscountBlock(tariff.amount, tariff.discount)}{FactPrice(tariff.amount, tariff.discount)} ₽</p></div>) : <div id={"tariff-" + tariff.hours} key={tariff.hours} className="radio" onClick={() => ClickTariff(tariff.hours, FactPrice(tariff.amount, tariff.discount), FactPrice(tariff.amount_usd, tariff.discount))}><span className="circle"><span></span></span>{tariff.hours/24} {t('game_days')}<p className="price">{DiscountBlock(tariff.amount, tariff.discount)}{FactPrice(tariff.amount, tariff.discount)} ₽</p></div>
        );
    }

    function Gallery2() {
        const screenshotWord = (count) => {
            if (count === 1) {
                return t('game_screenshots_w1');//'изображение';
            } else if (count >= 2 && count <= 4) {
                return t('game_screenshots_w2');//'изображения';
            } else {
                return t('game_screenshots_w3');//'изображений';
            }
        };
        const videoWord = (count) => {
            return t('game_videos');
        };

        const count = ScreenshotsArray.length + VideosArray.length;
        const [currentPage, setCurrentPage] = useState(1);
        const itemsPerPage = 3;

        const totalPages = Math.ceil(count / itemsPerPage);

        //Индексы для отображаемых элементов
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = [...VideosArray, ...ScreenshotsArray].slice(indexOfFirstItem, indexOfLastItem);

        if (count > 0) {
            return (
                <div className="block-cont screens">
                    <div className="pre-title-icon"><i className="icon screen-img"></i>{t('game_gallery')} <p>{ VideosArray.length > 0 ? <>{VideosArray.length} {videoWord(VideosArray.length)} / </> : "" }
                                                                                                              { ScreenshotsArray.length > 0 ? <>{ScreenshotsArray.length} {screenshotWord(ScreenshotsArray.length)}</> : "" }</p></div>
                    <div className="carousel-screens">
                        {totalPages > 1 && currentPage > 1 ? <p className="car-link active" style={{marginRight:'20px'}} onClick={() => setCurrentPage(currentPage - 1)}><i className="icon arrow-left"></i></p> : (count > 3 ? <p className="car-link" style={{marginRight:'20px'}}><i className="icon arrow-left"></i></p> : "") }

                        {currentItems.map((item, index) => (
                            <div key={index} className="car-img-wrapper">
                                <div className="car-img">
                                    {item.type === 'video' ? <video className="video-custom" controls><source src={item.url} type="video/mp4"/>Your browser does not support the video tag.</video> : <ModalImage small={item.small} large={item.big} alt="" hideDownload={true} hideZoom={true} />}
                                </div>
                            </div>
                        ))}

                        {totalPages > 1 && currentPage < totalPages && (<p className="car-link active" onClick={() => setCurrentPage(currentPage + 1)}><i className="icon arrow-right"></i></p>)}
                    </div>
                </div>
            );
        }
        return null;
    }
    function Gallery() {
        const screenshotWord = (count) => {
            if (count === 1) {
                return t('game_screenshots_w1'); // 'изображение';
            } else if (count >= 2 && count <= 4) {
                return t('game_screenshots_w2'); // 'изображения';
            } else {
                return t('game_screenshots_w3'); // 'изображений';
            }
        };
        const videoWord = (count) => {
            return t('game_videos');
        };

        const count = ScreenshotsArray.length + VideosArray.length;
        const [currentIndex, setCurrentIndex] = useState(0); // Текущий индекс элемента
        const itemsPerPage = 3; // Всегда отображать 3 элемента

        // Полный массив элементов, чтобы показывать
        const allItems = [...VideosArray, ...ScreenshotsArray];

        if (count > 0) {
            // Вычисляем индекс первого отображаемого элемента
            const displayedItems = allItems.slice(currentIndex, currentIndex + itemsPerPage);

            return (
                <div className="block-cont screens">
                    <div className="pre-title-icon">
                        <i className="icon screen-img"></i>{t('game_gallery')}<p>{ VideosArray.length > 0 ? <>{VideosArray.length} {videoWord(VideosArray.length)} / </> : "" } { ScreenshotsArray.length > 0 ? <>{ScreenshotsArray.length} {screenshotWord(ScreenshotsArray.length)}</> : "" }</p>
                    </div>
                    <div className="carousel-screens">
                        {currentIndex > 0 ? <p className="car-link active" style={{ marginRight: '20px' }} onClick={() => setCurrentIndex(currentIndex - 1)}><i className="icon arrow-left"></i></p> : <p className="car-link" style={{ marginRight: '20px' }}><i className="icon arrow-left"></i></p>}

                        {displayedItems.map((item, index) => (
                            <div key={index} className="car-img-wrapper">
                                <div className="car-img">
                                    {item.type === 'video' ? (
                                        <video className="video-custom" controls><source src={item.url} type="video/mp4"/>Your browser does not support the video tag.</video>
                                    ) : (
                                        <ModalImage small={item.small} large={item.big} alt="" hideDownload={true} hideZoom={true} />
                                    )}
                                </div>
                            </div>
                        ))}

                        {currentIndex + itemsPerPage < count ? <p className="car-link active" onClick={() => setCurrentIndex(currentIndex + 1)}><i className="icon arrow-right"></i></p> : <p className="car-link"><i className="icon arrow-right"></i></p>}
                    </div>
                </div>
            );
        }
        return null;
    }

    function Anticheats() {

        if (AnticheatsArray.length > 0) {
            return (
            <>
                <p className="accept-anticheat"><i className="icon shield"></i>{t('game_anticheats')}</p>
                <div className="accept-anticheat-list">
                    {AnticheatsArray.map((anticheat) => (
                        <p key={anticheat.title} className={`tooltip ${anticheat.status === 2 ? 'risks orange' : 'undetected green'}`}>
                            <span className="status"></span>{anticheat.title}
                            <span className="tooltiptext">
                                <span className="title">{anticheat.status === 2 ? 'Have risks' : 'Undetected'}</span>
                            </span>
                        </p>
                    ))}
                </div>
            </>
            );
        }
    }

    function Requirements() {

        if (RequirementsArray.length > 0) {
            return (
            <>
                <div className="block-cont system-recom">
                    <div className="pre-title-icon"><i className="icon products"></i>{t('game_requirements')}</div>
                    <div className="systems">
                        {RequirementsArray.map((req) => (
                            <div key={req.title} className="system-wrapper">
                                <div className="system">
                                    <span><i className="icon server"></i>{req.title}:</span><b>{req.value}</b>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>
            );
        }
    }

    let currentCategory = 0;
    let currentTab = 0;
    function ClickCategory(category) {

        //Меняем раздел
        document.getElementById("tab-" + currentCategory + "-" + currentTab).classList.remove("show");
        currentTab = 0;
        document.getElementById("tab-" + category + "-" + currentTab).classList.add("show");

        //Переключаем вкладку
        document.getElementById("category-" + currentCategory).classList.remove("active");
        document.getElementById("category-" + category).classList.add("active");

        //Скрываем старые и отображаем новые разделы
        var elementsHide = document.getElementsByClassName("tabs" + currentCategory);
        for (var i = 0; i < elementsHide.length; i++) {
            elementsHide[i].classList.add("hide");
        }
        var elementsShow = document.getElementsByClassName("tabs" + category);
        for (var i = 0; i < elementsShow.length; i++) {
            elementsShow[i].classList.remove("hide");
        }

        currentCategory = category;
    }
    function ClickFunctions(category, tab) {

        //Скрываем старый раздел
        document.getElementById("tab-" + category + "-" + currentTab).classList.remove("show");
        currentTab = tab;

        //Отображаем новый раздел
        document.getElementById("tab-" + category + "-" + tab).classList.add("show");
    }
    function UnpackFeaturesArray(props) {
        
        const getArray = (features) => {
            return features.split(", ");
        };
        const featureArray = getArray(props.features);

        return (
        <>
            {featureArray.map((feature, index) => (
                <b key={index}><i className="icon plus" style={{background: "#0000001f"}}></i>{feature}</b>
            ))}
        </>
        );
    }
    function Features() {
        if (FeaturesArray.length > 0) {
            return (
            <>
                <div className="block-cont functions">
                    <div className="pre-title-icon"><i className="icon gamepad"></i>{t('game_features')}</div>
                    <div className="radio-box">
                        {FeaturesArray.map((req, index) => (
                            <div id={"category-" + index} key={req.name} className={"radio " + (index === 0 ? "active" : "")} onClick={() => ClickCategory(index)}>{req.name}</div>
                        ))}
                    </div>

                    {FeaturesArray.map((req, index) => (
                        req.more.map((feature, featureIndex) => (
                            <div id={"tab-" + index + "-" + featureIndex} key={featureIndex} className={"fadeout dropdown clickable " + "tabs" + index + " " + (featureIndex === 0 ? "show " : "") + (index === 0 ? "" : "hide")} onClick={() => ClickFunctions(index, featureIndex)}>
                                <p className="title">{feature.name}<i className="icon arrow-down"></i></p>
                                <span>
                                    <UnpackFeaturesArray features={feature.features} />
                                </span>
                            </div>
                        ))
                    ))}
                </div>
            </>
            );
        }
    }

    function CurrentStatus() {
        if (Status == -1) {
            return (
                <b style={{'color': '#6382F5'}}>{t('game_status_disabled')}</b>
            );
        }
        else if (Status == 0) {
            return (
                <b style={{'color': '#ebad4e'}}>{t('game_status_updating')}</b>
            );
        }
        else if (Status == 1) {
            return (
                <b style={{'color': '#4AC297'}}>{t('game_status_work')}</b>
            );
        }
        else if (Status == 2) {
            return (
                <b style={{'color': '#ebad4e'}}>{t('game_status_testing')}</b>
            );
        }
    }

    if (Identifier != null) {
        return (
        <>
            <Popups/>

            <Header/>

            <section id="product" className="fadeout">
                <div className="product-header">
                    <div className="container">
                        <div className="content">
                            <div className="navigation">
                                <Link to="/games"><i className="icon products"></i>{t('game_products')}</Link>
                                <span>&gt;</span>
                                <Link to="">{Name}</Link>
                            </div>
                            <div className="product-main-info">
                                <img src={"https://api.0xcheats.net/Images/Games/" + Identifier +"/logo.jpg"} alt="" draggable="false"/>
                                <div>
                                    <p className="name">{Name}</p>
                                    <span>{t('game_released')}: <b>{ReleaseDate}</b></span>
                                    <span>{t('game_updated')}: <b>{UpdateDate}</b></span>
                                    <span className="status">{t('game_status')}: <CurrentStatus/></span>
                                </div>
                            </div>
                            <p className="description">{Description}</p>
                            <div className="hl"></div>
                            <Anticheats/>
                        </div>
                        <div className="block-payment-wrapper">

                            <div id="can-buy" className={InvalidToken ? "hide" : ""}>
                                <div className="block-payment">
                                    <p className="title"><span>{t('game_offer_1')}</span> {t('game_offer_2')}</p>
                                    <p className="description">{t('game_offer_description')}</p>
                                    <div className="radio-box">
                                        <Tariffs/>
                                    </div>
                                    <div style={{'display':'flex', 'gap':'10px'}}>
                                        <a id="buy-button" className="btn black clickable hide" onClick={() => ClickPayment()}>Оплатить<span id="buy-button-price">0 ₽</span></a>
                                        <a id="buy-button2" className="btn black clickable" style={{display: "none"}}><img src={loading_image} width='100%'/></a>
                                        <a id="buy-button-usd" className="btn black clickable hide" onClick={() => ClickPaymentUSD()}>Payment<span id="buy-button-price-usd">0 $</span></a>
                                        <a id="buy-button-usd2" className="btn black clickable" style={{display: "none"}}><img src={loading_image} width='100%'/></a>
                                    </div>
                                </div>

                                { Status == -1 || Status == 2 ? "" :
                                    <div className="support">
                                        <i style={{padding:'14px', borderRadius:'0px'}} className="icon support"></i>
                                        <span>{t('game_support_q1')} <br/> {t('game_support_q2')}</span>
                                        <Link to="/account/help" className="btn black">{t('game_support')}</Link>
                                    </div> }
                            </div>

                            <div id="cant-buy" className={InvalidToken ? "" : "hide"}>
                                <div className="block-payment">
                                    <p className="title"><span>{t('game_offer_1')}</span> {t('game_offer_2')}</p>
                                    <p className="description">{t('game_offer_auth')}</p>
                                    <Link to="/auth" className="btn black clickable" style={{'background': '#000'}}>{t('game_offer_auth_button')}</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="product-more-info">
                    <div className="container">
                        <div className="content">
                            <Gallery/>
                            <Requirements/>
                            <Features/>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
        );
    }
    else {
        return (
        <>
            <Popups/>

            <Header/>

            <section id="product" className="fadeout">
                <div className="product-header">
                    <div className="container">
                        <div className="content">
                            <div className="navigation">
                            </div>
                            <div className="product-main-info">
                                <img src={empty_image} alt="" className="loading"/>
                                <div>
                                    <p className="name loading">Software Name</p>
                                </div>
                            </div>
                            <div className="hl"></div>
                            <p className="accept-anticheat"><i className="icon shield"></i>{t('game_anticheats')}</p>
                            <div className="accept-anticheat-list">

                                <p className="green loading">
                                    <span className="status"></span>Unknown Anti-Cheat
                                    <span className="tooltiptext">
                                        <span className="title">Undetected</span>
                                    </span>
                                </p>

                                <p className="green loading">
                                    <span className="status"></span>Unknown Anti-Cheat
                                    <span className="tooltiptext">
                                        <span className="title">Undetected</span>
                                    </span>
                                </p>

                                <p className="green loading">
                                    <span className="status"></span>Unknown Anti-Cheat
                                    <span className="tooltiptext">
                                        <span className="title">Undetected</span>
                                    </span>
                                </p>

                            </div>
                        </div>
                        <div className="block-payment-wrapper">
                            <div className="block-payment">
                                <p className="title"><span>{t('game_offer_1')}</span> {t('game_offer_2')}</p>
                                <br/>
                                {/*<p className="description">Выберите удобный тарифный план для покупки продукта</p>*/}
                                <div className="radio-box">

                                    <div className="system-wrapper loading">Loading<br/><br/><br/></div>
                                    <br/>
                                    <div className="system-wrapper loading">Loading<br/><br/><br/></div>
                                    <br/>
                                    <div className="system-wrapper loading">Loading<br/><br/><br/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="product-more-info">
                    <div className="container">
                        <div className="content">
                            <div className="block-cont screens">
                                <div className="pre-title-icon">
                                    <i className="icon screen-img"></i>{t('game_gallery')}<p></p>
                                </div>
                                <div className="carousel-screens">

                                    <div className="car-img-wrapper">
                                        <div className="car-img">
                                            <img style={{width: '100%', height: '100%'}} src={empty_image} alt="" className="loading"/>
                                        </div>
                                    </div>

                                    <div className="car-img-wrapper">
                                        <div className="car-img">
                                            <img style={{width: '100%', height: '100%'}} src={empty_image} alt="" className="loading"/>
                                        </div>
                                    </div>

                                    <div className="car-img-wrapper">
                                        <div className="car-img">
                                            <img style={{width: '100%', height: '100%'}} src={empty_image} alt="" className="loading"/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="block-cont system-recom">
                                <div className="pre-title-icon"><i className="icon gamepad"></i>{t('game_requirements')}</div>
                                <div className="systems">
                                    <div className="system-wrapper loading">Loading<br/></div>
                                    <br/><br/>
                                </div>
                            </div>
                            <div className="block-cont functions">
                                <div className="pre-title-icon"><i className="icon screen-img"></i>{t('game_features')}</div>

                                <div className="system-wrapper loading">Loading<br/><br/><br/><br/></div>
                                <br/>
                                <div className="system-wrapper loading">Loading<br/><br/><br/><br/></div>
                                <br/>
                                <div className="system-wrapper loading">Loading<br/><br/><br/><br/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
        );
    }
};
export default Game;