import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import AccountReseller_Buy from './Modals/AccountReseller_Buy';

import Popups, { ShowSuccess, ShowError } from './Modules/Popups';
import AccountReseller_Week from './Modules/AccountReseller_Week';
import AccountReseller_Month from './Modules/AccountReseller_Month';

import Account_Header from './Modules/Account_Header';
import Account_Navigation from './Modules/Account_Navigation';

import empty_image from "../images/empty.png"


const AccountReseller = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const [InvalidToken, SetInvalidToken] = React.useState(false);

    const [Id, SetId] = React.useState(0);
    const [Username, SetUsername] = React.useState("Username");
    const [Avatar, SetAvatar] = React.useState("Avatar");
    const [Balance, SetBalance] = React.useState(0);

    const [BulksParsed, SetBulksParsed] = React.useState(false);
    const [BulksArray, SetBulksArray] = React.useState([]);

    const [HacksParsed, SetHacksParsed] = React.useState(false);
    const [HacksArray, SetHacksArray] = React.useState([]);

    function decrypt(encryptedData) {
        const encryptedBytes = atob(encryptedData);
        const keyBytes = unescape(encodeURIComponent("MyEncryptionKey123"));

        const decryptedBytes = new Uint8Array(encryptedBytes.length);

        for (let i = 0; i < encryptedBytes.length; i++) {
            const encryptedByte = encryptedBytes.charCodeAt(i);
            const keyByte = keyBytes.charCodeAt(i % keyBytes.length);

            // Пропускаем символы, не соответствующие ASCII
            if (encryptedByte > 255 || keyByte > 255) {
                decryptedBytes[i] = encryptedByte;
            } else {
                decryptedBytes[i] = encryptedByte ^ keyByte;
            }
        }

        const decoder = new TextDecoder('utf-8');
        const decryptedString = decoder.decode(decryptedBytes);

        return JSON.parse(decryptedString);
    }
    function GetHwid() {
        var browser = navigator.hardwareConcurrency + "," + navigator.languages; //navigator.userAgent
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }

    useEffect(()=> {
        if (cookies.balance != null) SetBalance(cookies.balance);
        
        axios.post('https://api.0xcheats.net/Account/Reseller/Info', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid() }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);

            if (json.success == "true") {

                //User
                SetId(json.id);
                SetUsername(json.username);

                if (json.avatar.length == 0)
                    SetAvatar("https://api.0xcheats.net/Images/Avatars/invalid.jpg");
                else
                    SetAvatar("https://api.0xcheats.net/Images/Avatars/" + json.avatar);

                SetBalance(json.balance);
                setCookie("balance", json.balance, { path: "/" });

                //Ключи
                SetBulksParsed(true);
                SetBulksArray(json.bulks);

                //Список софтов
                SetHacksParsed(true);
                SetHacksArray(json.hacks);
            }
            else if (json.failed != null) {
                SetInvalidToken(true);
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {

        });
    }, [])

    if (InvalidToken) {
        removeCookie("token", { path: "/" });
        removeCookie("is_reseller", { path: "/" });
        return <Navigate to="/auth" replace />;
    }


    function DateFormat(date) {
        const newDate = new Date(date);
        const day = newDate.getDate().toString().padStart(2, '0');
        const month = (newDate.getMonth() + 1).toString().padStart(2, '0'); // Месяцы начинаются с 0
        const year = newDate.getFullYear();
        const hours = newDate.getHours().toString().padStart(2, '0');
        const minutes = newDate.getMinutes().toString().padStart(2, '0');
        const formattedDate = `${day}.${month}.${year} в ${hours}:${minutes}`;
        return formattedDate;
    }

    function ShowKeys(bulkId) {
        axios.post('https://api.0xcheats.net/Account/Reseller/ShowKeys', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid(), 'bulk': bulkId }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);

            if (json.success == "true") {

                const parentElement = document.getElementById(bulkId);
                if (!parentElement.hasChildNodes()) {

                    json.keys.forEach((key, index) => {
                        const newDiv = document.createElement('div');
                        newDiv.style.fontSize = '16px';
                        newDiv.style.color = key.status === 0 ? '#007b16' : '#b1b1b1';
                        newDiv.innerHTML = `<b>${key.code}</b>`;
                        parentElement.appendChild(newDiv);
                    });

                    document.getElementById("copy" + bulkId).style.display = "flex";
                }
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {

        });
    }
    function ToggleKeys(bulkId) {
        ShowKeys(bulkId);

        let keysBlock = document.getElementById(bulkId);
        let iconBlock = document.getElementById("icon" + bulkId);

        if (keysBlock != null) {
            if (keysBlock.style.display == "block") {
                iconBlock.classList.remove("eye2");
                iconBlock.classList.add("eye3");
                keysBlock.style.display = "none";
            }
            else {
                iconBlock.classList.remove("eye3");
                iconBlock.classList.add("eye2");
                keysBlock.style.display = "block";
            }
        }
    }
    function CopyKeys(bulkId) {
        ShowKeys(bulkId);

        let keysBlock = document.getElementById(bulkId);

        if (keysBlock != null) {
            let alreadyShow = false;
            if (keysBlock.style.display != "block")
            {
                keysBlock.style.display = "block";
            }
            else {
                alreadyShow = true;
            }

            const range = document.createRange();
            range.selectNode(keysBlock);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);

            try {
                document.execCommand('copy');
                ShowSuccess("Ключи скопированы!");
            } catch (err) {
                ShowError(err);
            }

            window.getSelection().removeAllRanges();
            if (!alreadyShow) {
                keysBlock.style.display = "none";
            }
        }
    }
    function HideBulk(bulkId) {
        axios.post('https://api.0xcheats.net/Account/Reseller/HideBulk', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid(), 'bulk': bulkId }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);

            if (json.success == "true") {
                ShowSuccess("Selected bulk successfully hidden!");

                const parentElement = document.getElementById("bulk" + bulkId);
                if (parentElement != null) {
                    parentElement.style.display = "none";
                }
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {

        });
    }

    function CheckCode() {
        let code = document.getElementById('check_code').value;

        //Проверяем поле
        if (code.length < 25) {
            ShowError(t('reseller_error_1'));
            return;
        }

        document.getElementById('check_code_button').style.display = "none";
        document.getElementById('check_code').disabled = true;
        document.getElementById('check_code_found_activated').style.display = "none";
        document.getElementById('check_code_found_inactivated').style.display = "none";

        axios.post('https://api.0xcheats.net/Account/Reseller/CheckCode', qs.stringify({ 'token': cookies.token, 'code': code }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);

            if (json.success == "true") {
                if (json.activated == 1) {
                    document.getElementById('check_code_found_activated').style.display = "flex";
                    document.getElementById('check_code_value').innerHTML = code;
                    document.getElementById('check_code_activated_at').innerHTML = DateFormat(json.date);
                    document.getElementById('check_code_username').innerHTML = json.username;
                    document.getElementById('check_code_subscription').innerHTML = json.software;
                    if (json.hours >= 1000000) {
                        document.getElementById('check_code_days').innerHTML = "Lifetime";
                    }
                    else {
                        document.getElementById('check_code_days').innerHTML = (json.hours / 24) + " дней";
                    }
                }
                else {
                    document.getElementById('check_code_found_inactivated').style.display = "flex";
                    document.getElementById('check_code_value2').innerHTML = code;
                    document.getElementById('check_code_subscription2').innerHTML = json.software;
                    if (json.hours >= 1000000) {
                        document.getElementById('check_code_days2').innerHTML = "Lifetime";
                    }
                    else {
                        document.getElementById('check_code_days2').innerHTML = (json.hours / 24) + " дней";
                    }
                }
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            document.getElementById('check_code_button').style.display = "flex";
            document.getElementById('check_code').disabled = false;
        });
    }

    function Keys() {
        if (BulksParsed) {
            return BulksArray.map((bulk) =>
            <>
            <div key={bulk.id} id={"bulk" + bulk.id} className="block-wrapper">
                <div className="block">
                    <div className="header">
                        <a style={{marginRight:'4px'}}>Ключи</a> <b id="check_code_subscription" style={{marginRight:'4px'}}>{bulk.identifier}</b> <a style={{marginRight:'4px'}}>на</a> <b id="check_code_days">{bulk.hours} часов</b><a style={{marginLeft:'4px', marginRight:'4px'}}>|</a><b>{DateFormat(bulk.date)}</b>
                        <p className="icon-wrapper clickable" style={{marginLeft:'auto', marginRight:'0px', background:'rgba(228, 228, 228, 0)'}} onClick={() => ToggleKeys(bulk.id)}><i id={"icon" + bulk.id} className="icon eye3" style={{background:'rgb(68, 68, 68)'}}></i></p>
                        <p id={"copy" + bulk.id} className="icon-wrapper clickable" style={{marginLeft:'0px', marginRight:'0px', background:'rgba(228, 228, 228, 0)', display:'none'}} onClick={() => CopyKeys(bulk.id)}><i className="icon copy" style={{background:'rgb(68, 68, 68)'}}></i></p>
                        <p className="icon-wrapper clickable" style={{marginLeft:'0px', marginRight:'0px', background:'rgba(228, 228, 228, 0)'}} onClick={() => HideBulk(bulk.id)}>X</p>
                    </div>
                    <div id={bulk.id} style={{display:'none'}}>
                        {/*<br/>*/}
                        {/*{bulk.keys.map((key, index) => (
                            <div key={index} style={{fontSize: '16px', color: key.status === 0 ? '#007b16' : '#b1b1b1'}}><b>{key.code}</b></div>
                        ))}*/}
                    </div>
                </div>
            </div>
            <br/>
            </>
            );
        }
        else {
            
        }
    }
    function Products() {
        if (HacksParsed) {
            return HacksArray.map((hack) =>
            <div className="product-wrapper" key={hack.identifier}>
                <div className="product">
                    <img src={"https://api.0xcheats.net/Images/Games/" + hack.identifier +"/logo.jpg"} alt="" draggable="false"/>
                    <div className="info">
                        <div className="text">
                            <span className="status-text active">{t('reseller_discount')}: {hack.discount}%</span>
                            <p className="name">{hack.name}</p>
                        </div>
                        <AccountReseller_Buy token={cookies.token} identifier={hack.identifier} name={hack.name}/>
                    </div>
                </div>
            </div>
            );
        }
        else {
            return (
            <>
                <div className="product-wrapper">
                    <div className="product loading"></div>
                </div>

                <div className="product-wrapper">
                    <div className="product loading"></div>
                </div>

                <div className="product-wrapper">
                    <div className="product loading"></div>
                </div>
            </>
            );
        }
    }

    if (Id == 0) {
        return (
        <>
            <Popups/>
            <section id="settings" className="profile-page">
                <Account_Navigation active={"reseller"}/>
                <div className="container">
                    <div className="content">
                        <Account_Header username={Username} id={Id} avatar={Avatar} balance={Balance}/>
                        <div className="hl-reson"></div>
                        <div className="pre-title no-m fadeout"><i className="icon products"></i>{t('reseller_statistic')}</div>

                        <div className="blocks fadeout">
                            <div className="block-wrapper">
                                <div className="block" style={{height: '25vh', width: "100%"}}>
                                </div>
                            </div>
                            <div className="block-wrapper">
                                <div className="block" style={{height: '25vh', width: "100%"}}>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="history" className="profile-page">

                <div className="container fadeout">
                    <div className="content">

                        <div className="hl-reson"></div>
                        <div className="pre-title no-m"><i className="icon products"></i>{t('reseller_products')}</div>

                        <div className="history-buy no-m">

                            <div className="product-wrapper">
                                <div className="product loading"></div>
                            </div>

                            <div className="product-wrapper">
                                <div className="product loading"></div>
                            </div>

                            <div className="product-wrapper">
                                <div className="product loading"></div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
        );
    }
    else {
        return (
        <>
            <Popups/>
            <section id="settings" className="profile-page">
                <Account_Navigation active={"reseller"}/>
                <div className="container">
                    <div className="content">
                        <Account_Header username={Username} id={Id} avatar={Avatar} balance={Balance}/>
                        <div className="hl-reson"></div>
                        <div className="pre-title no-m fadeout"><i className="icon products"></i>{t('reseller_statistic')}</div>

                        <div className="blocks fadeout">
                            <div className="block-wrapper">
                                <div className="block" style={{height: '25vh', width: "100%"}}>
                                    <div className="header">
                                        <p className="icon-wrapper"><i className="icon week"></i></p>{t('reseller_week_keys')}
                                    </div>
                                    <br/><AccountReseller_Week/><br/>
                                </div>
                            </div>
                            <div className="block-wrapper">
                                <div className="block" style={{height: '25vh', width: "100%"}}>
                                    <div className="header">
                                        <p className="icon-wrapper"><i className="icon month"></i></p>{t('reseller_month_keys')}
                                    </div>
                                    <br/><AccountReseller_Month/><br/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="settings" className="profile-page">
                <div className="container">
                    <div className="content">
                        <div className="hl-reson"></div>
                        <div className="pre-title no-m fadeout"><i className="icon keys"></i>{t('reseller_check_keys')}</div>
                        <div className="blocks fadeout">
                            <div className="input activate-key" style={{'display': 'flex', alignItems: 'center'}}>
                                <p id="check_code_button" className="btn black" style={{borderRadius: '30px', 'width': 'auto', 'cursor': 'pointer', 'transition': '.25s all'}} onClick={() => CheckCode()}>{t('reseller_check_keys_button')}</p>
                                <input id="check_code" type="text" placeholder={t('reseller_check_keys_input')} style={{'width': '300px', fontWeight: '600', 'padding': '18px 20px', 'border': '1px solid rgba(0, 0, 0, .1)', marginLeft: '-50px', borderRadius: '30px', paddingLeft: '65px', fontSize: '16px', 'height': '55px'}}/>
                            </div>
                        </div>

                        <div id="check_code_found_activated" className="blocks fadeout" style={{'display': 'none', marginTop: '40px'}}>
                            <div className="block-wrapper">
                                <div className="block">
                                    <div className="header"><b id="check_code_value">12345-12345-12345-12345-12345</b></div><br/>
                                    <div style={{fontSize: '16px', paddingBottom: '5px'}}>{t('reseller_check_keys_activated')} <b id="check_code_activated_at">2024-03-06 19:13:52</b> {t('reseller_check_keys_byuser')} <b id="check_code_username">Superior</b></div>
                                    <div style={{fontSize: '16px'}}>{t('reseller_check_keys_subscription')} <b id="check_code_subscription">RAGEMP (Full)</b> на <b id="check_code_days">30 дней</b></div>
                                </div>
                            </div>
                        </div>
                        <div id="check_code_found_inactivated" className="blocks fadeout" style={{'display': 'none', marginTop: '40px'}}>
                            <div className="block-wrapper">
                                <div className="block">
                                    <div className="header"><b id="check_code_value2">12345-12345-12345-12345-12345</b></div><br/>
                                    <div style={{fontSize: '16px', paddingBottom: '5px'}}>{t('reseller_check_keys_notactivated')}</div>
                                    <div style={{fontSize: '16px'}}>{t('reseller_check_keys_subscription')} <b id="check_code_subscription2">RAGEMP (Full)</b> на <b id="check_code_days2">30 дней</b></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="settings" className="profile-page">
                <div className="container">
                    <div className="content">
                        <div className="hl-reson"></div>
                        <div className="pre-title no-m fadeout"><i className="icon reseller2"></i>{t('reseller_your_keys')}</div>
                        <div className="history-buy no-m">
                            <div className="blocks fadeout">
                                <Keys/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="history" className="profile-page">
                <div className="container fadeout">
                    <div className="content">
                        <div className="hl-reson"></div>
                        <div className="pre-title no-m"><i className="icon products"></i>{t('reseller_products')}</div>
                        <div className="history-buy no-m">
                            <Products/>
                        </div>
                    </div>
                </div>
            </section>
        </>
        );
    }
};
export default AccountReseller;