import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import {ShowError} from '../Modules/Popups';

const FooterTerms = (props) => {
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const isHover = e => e.parentElement.querySelector(':hover') === e;
    function Close() {
        document.getElementById('modal-wrapper').classList.remove('show');
        document.getElementById('modal-wrapper').classList.remove('fadeout');
        document.getElementById('modal').classList.remove('show');
    }
    function CloseFull() {
        const hovered = isHover(document.getElementById('modal'));
        if (!hovered)
            Close();
    }
    function Show() {
        ReactDOM.render(<FooterTerms identifier={props.identifier} name={props.name}/>, document.getElementById('modals'));

        setTimeout(() => {
            document.getElementById('modal-wrapper').onclick = CloseFull;
            document.getElementById('modal-wrapper').classList.add('show');
            document.getElementById('modal-wrapper').classList.add('fadeout');
            document.getElementById('modal').classList.add('show');
            document.getElementById('body-loading').style.display = "block";
            //document.getElementById('body').style.display = "none";
            document.getElementById('close-terms-button').onclick = Close;
        }, 200);
    }

    return (
    <>
        <div id="modal-wrapper" className="modal-wrapper" onClick={CloseFull}>
            <div id="modal" className="modal cash-up">

                <div id="body-loading" className="body" style={{ maxHeight: "80vh", 'overflow': 'auto' }}>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p className="pre-title2 fadeout">{t('terms_title')}</p>
                        <a style={{ marginLeft: "auto" }}><div id="close-terms-button" className="pre-title2" style={{ fontSize: '30px', cursor: 'pointer'}} onClick={Close}>x</div></a>
                    </div>

                    <div className="h1"></div>
                    <ul className="pre-text fadeout">
                        
                        <b>{t('terms_description_1')}</b><br/><br/>
                        {t('terms_description_1_1')}<br/>
                        {t('terms_description_1_2')}<br/>
                        {t('terms_description_1_3')}<br/>
                        {t('terms_description_1_4')}<br/>
                        {t('terms_description_1_5')}<br/>
                        {t('terms_description_1_6')}<br/>
                        
                        <br/>
                        <b>{t('terms_description_2')}</b><br/><br/>
                        <b>2.1.</b> {t('terms_description_2_1')}<br/>
                        {t('terms_description_2_2')}<br/>
                        {t('terms_description_2_3')}<br/>
                        {t('terms_description_2_4')}<br/>
                        
                        <br/><li>
                        {t('terms_description_2_5')}<br/>
                        </li>

                        <br/><li>
                        {t('terms_description_2_6')}<br/>
                        {t('terms_description_2_7')}<br/>
                        </li>

                        <br/><li>
                        {t('terms_description_2_8')}<br/>
                        </li>

                        <br/><li>
                        {t('terms_description_2_9')}<br/>
                        </li>

                        <br/><li>
                        {t('terms_description_2_10')}<br/>
                        </li>

                        <br/>
                        <b>2.2.</b> {t('terms_description_3')}<br/>
                        {t('terms_description_3_1')}<br/>
                        {t('terms_description_3_2')}<br/>
                        {t('terms_description_3_3')}<br/>

                        <br/>
                        <b>{t('terms_description_4')}</b><br/><br/>
                        {t('terms_description_4_1')}<br/>
                        {t('terms_description_4_2')}<br/>
                        {t('terms_description_4_3')}<br/>
                        {t('terms_description_4_4')}<br/>
                        {t('terms_description_4_5')}<br/>
                        {t('terms_description_4_6')}<br/>
                        {t('terms_description_4_7')}<br/>

                        <br/>
                        <b>{t('terms_description_5')}</b><br/><br/>
                        {t('terms_description_5_1')}<br/>
                        {t('terms_description_5_2')}<br/>
                        {t('terms_description_5_3')}<br/>
                        {t('terms_description_5_4')}<br/>
                        {t('terms_description_5_5')}<br/>
                        {t('terms_description_5_6')}<br/>

                        <br/>
                        <b>{t('terms_description_6')}</b><br/><br/>
                        {t('terms_description_6_1')}<br/>
                        {t('terms_description_6_2')}<br/>
                        {t('terms_description_6_3')}<br/>
                        {t('terms_description_6_4')}<br/>

                        <br/>
                        <b>{t('terms_description_7')}</b><br/><br/>
                        {t('terms_description_7_1')}<br/>
                        {t('terms_description_7_2')}<br/>
                        {t('terms_description_7_3')}<br/>

                        <br/>
                        <b>{t('terms_description_8')}</b><br/><br/>
                        {t('terms_description_8_1')}<br/>
                        {t('terms_description_8_2')}<br/>

                        <br/>
                        <b>{t('terms_description_9')}</b><br/><br/>
                        {t('terms_description_9_1')}<br/>
                        {t('terms_description_9_2')}<br/>

                        <br/>
                        <b>{t('terms_description_10')}</b><br/><br/>
                        {t('terms_description_10_1')}<br/>
                        {t('terms_description_10_2')}<br/>
                        {t('terms_description_10_3')}<br/>
                        {t('terms_description_10_4')}<br/>
                        {t('terms_description_10_5')}<br/>
                        {t('terms_description_10_6')}<br/>
                        {t('terms_description_10_7')}<br/>

                        <br/>
                        <b>{t('terms_description_11')}</b><br/><br/>
                        {t('terms_description_11_1')}<br/>
                        {t('terms_description_11_2')}<br/>
                        {t('terms_description_11_3')}<br/>

                        <br/>
                        <b>{t('terms_description_12')}</b><br/><br/>
                        {t('terms_description_12_1')}<br/>
                        {t('terms_description_12_2')}<br/>
                        {t('terms_description_12_3')}<br/>
                        {t('terms_description_12_4')}<br/>


                        <br/><br/>
                        {t('terms_date')}
                    </ul>
                </div>

            </div>
        </div>

        <a className="clickable" onClick={() => Show()}>{t('footer_terms')}</a>
    </>
    );
};
export default FooterTerms;