import React from 'react';
import { useTranslation } from 'react-i18next';

const Index_Faq = () => {
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    var last_id = 0;
    function ClickFaq(id)
    {
        if (last_id !== 0 && last_id !== id)
            document.getElementById('faq' + last_id).classList.remove('show');

        document.getElementById('faq' + id).classList.toggle('show');

        last_id = id;
    }

    return (
    <section id="faq" className="fadeout">
        <div className="container">
            <div className="content">
                <div className="block left">
                    <p className="big-title">FAQ</p>
                    <p className="middle-title">{t('faq_description')}</p>
                </div>
                <div className="block right">

                    <div id="faq1" className="dropdown" onClick={() => ClickFaq(1)}>
                        <p className="title">{t('faq_1_title')}<i className="icon arrow-down"></i></p>
                        <span>{t('faq_1_description')}</span>
                    </div>

                    <div id="faq2" className="dropdown" onClick={() => ClickFaq(2)}>
                        <p className="title">{t('faq_2_title')}<i className="icon arrow-down"></i></p>
                        <span>{t('faq_2_description')}</span>
                    </div>

                    <div id="faq3" className="dropdown" onClick={() => ClickFaq(3)}>
                        <p className="title">{t('faq_3_title')}<i className="icon arrow-down"></i></p>
                        <span>{t('faq_3_description')}</span>
                    </div>

                    <div id="faq4" className="dropdown" onClick={() => ClickFaq(4)}>
                        <p className="title">{t('faq_4_title')}<i className="icon arrow-down"></i></p>
                        <span>{t('faq_4_description')}</span>
                    </div>

                    <div id="faq5" className="dropdown" onClick={() => ClickFaq(5)}>
                        <p className="title">{t('faq_5_title')}<i className="icon arrow-down"></i></p>
                        <span>{t('faq_5_description')}</span>
                    </div>

                </div>
            </div>
        </div>
    </section>
    );
};
export default Index_Faq;