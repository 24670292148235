import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import { ShowSuccess, ShowError } from '../Modules/Popups';

const Account_Download = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const isHover = e => e.parentElement.querySelector(':hover') === e;
    function Close() {
        document.getElementById('modal-wrapper').classList.remove('show');
        document.getElementById('modal-wrapper').classList.remove('fadeout');
        document.getElementById('modal').classList.remove('show');
    }
    function CloseFull() {
        const hovered = isHover(document.getElementById('modal'));
        if (!hovered)
            Close();
    }
    function Show() {
        ReactDOM.render(<Account_Download name={props.name} identifier={props.identifier} instruction={props.instruction} token={props.token}/>, document.getElementById('modals'));

        setTimeout(() => {
            document.getElementById('modal-wrapper').onclick = CloseFull;
            document.getElementById('modal-wrapper').classList.add('show');
            document.getElementById('modal-wrapper').classList.add('fadeout');
            document.getElementById('modal').classList.add('show');

            document.getElementById('body-operations').style.display = "block";
            document.getElementById('body-create-new').style.display = "none";

            document.getElementById('create-new-button').onclick = () => {
                document.getElementById('body-operations').style.display = "none";
                document.getElementById('body-create-new').style.display = "block";
            };
            document.getElementById('close-button').onclick = Close;
            document.getElementById('goback-button').onclick = () => {
                document.getElementById('body-operations').style.display = "block";
                document.getElementById('body-create-new').style.display = "none";
            };


        }, 200);
    }

    function Download(identifier) {
        document.getElementById("download-" + identifier).style.display = 'none';
        document.getElementById("download-" + identifier + "-wait").style.display = 'flex';

        let success = true;
        axios({
            method: 'post',
            url: 'https://api.0xcheats.net/Account/Download',
            data: qs.stringify({ 'token': cookies.token, 'identifier': identifier }),
            responseType: 'blob'
        })
        .then(function(response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', identifier + '_launcher.exe');
            document.body.appendChild(link);
            link.click();
        })
        .catch(function(error) {
            ShowError(error);

            success = false;
        })
        .finally(function() {
            document.getElementById("download-" + identifier).style.display = 'flex';
            document.getElementById("download-" + identifier + "-wait").style.display = 'none';

            if (success)
                Show();
        });
    }


    return (
    <>
        <div id="modal-wrapper" className="modal-wrapper" onClick={CloseFull}>
            <div id="modal" className="modal cash-up">
                <div className="header">
                    <img className="avatar" alt="" src={"https://api.0xcheats.net/Images/Games/" + props.identifier +"/logo.jpg"} draggable="false"/>
                    <p className="name">{t('account_instruction_header')} | <b>{props.name}</b></p>
                </div>

                <div id="body-operations" className="body">
                    <div style={{whiteSpace:'pre-line'}}>{props.instruction}</div><br/>
                    <div className="go-up fadeout">
                        <p id="close-button" className="btn black clickable" style={{background: 'rgb(0, 0, 0)',margin:'5px', width:'100%'}}>{t('account_instruction_button')}</p>
                    </div>
                </div>

            </div>
        </div>
        <div id={"download-" + props.identifier} className="status-view active" onClick={() => Download(props.identifier)}><i className="icon download"></i></div>
    </>
    );
};
export default Account_Download;