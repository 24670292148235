import React, { useEffect, useState, useRef } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Turnstile } from '@marsidev/react-turnstile';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import Header from './Header';
import Footer from './Footer';

import Popups, {ShowError} from './Modules/Popups';

import loading_image from '../images/loading2.gif';
import empty_image from "../images/empty.png"
import user_image from "../images/user.jpg"


const Register = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const captcha_ref = useRef(null);
    const [key, setKey] = useState(123);

    //Сбрасываем авторизацию
    //removeCookie("token", { path: "/" });
    removeCookie("is_coder", { path: "/" });
    removeCookie("is_partner", { path: "/" });
    removeCookie("is_reseller", { path: "/" });
    removeCookie("is_supporter", { path: "/" });

    function GetLang() {
        const language = navigator.language || navigator.userLanguage;
        //console.log(language); // Выведет язык в формате "en-US", "ru-RU", "zh-CN" и т.д.
        return language.split('-')[0];
    }
    useEffect(()=> {
        setTimeout(() => {
            if (cookies.lang == null) {
                let lang = GetLang();
                changeLanguage(lang);
                setCookie("lang", lang, { path: "/" });
            }
            else {
                changeLanguage(cookies.lang);
            }
        }, 100);
    }, [])

    const [Redirect, SetRedirect] = React.useState(false);
    if (Redirect) {
        return <Navigate to={"/auth"} replace />;
    }

    function validateEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }

    function StartRegister() {
        let username = document.getElementById("username").value;
        let email = document.getElementById("email").value;
        let password1 = document.getElementById("password1").value;
        let password2 = document.getElementById("password2").value;
        let promo = document.getElementById("promo").value;
        let captcha = captcha_ref.current?.getResponse();

        //Логин должен быть больше 3 символов
        if (username.length < 3) {
            ShowError("Username must be more than three characters!");
            return;
        }
        if (!validateEmail(email)) {
            ShowError("Enter valid E-mail address!");
            return;
        }

        //Пароли не совпадают
        if (password1 != password2) {
            ShowError("Passwords mismatch!");
            return;
        }

        //Пароль должен быть больше 6 символов
        if (password1.length < 6) {
            ShowError("Username must be more than 6 characters!");
            return;
        }

        document.getElementById("auth-button1").style.display = 'none';
        document.getElementById("auth-button2").style.display = 'flex';

        axios.post('https://api.0xcheats.net/Account/Register', qs.stringify({ 'username': username, 'email': email, 'password': password2, 'promo': promo, 'captcha': captcha }))
        .then(function(response) {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);
            
            if (json.success == "true") {
                setCookie("token", json.token, { path: "/" });
                SetRedirect(true);
            }
            else if (json.failed != null) {
                ShowError(json.failed);

                //Перезагружаем капчу
                setKey(Math.random());
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            document.getElementById("auth-button1").style.display = 'flex';
            document.getElementById("auth-button2").style.display = 'none';
        });
    }

    return (
    <>
        <Popups/>

        <Header/>

        <section id="settings" className="profile-page fadeout">
            <div className="container">
                <div className="content">

                    <div className="modal2-wrapper">
                        <div className="modal cash-up show">
                            <div className="header">
                                <img className="avatar" alt="" src={user_image} draggable="false"/>
                                <p className="name">{t('register_title')}</p>
                            </div>

                            <div id="body-loading" className="body">
                                <p className="pre-title fadeout" style={{marginBottom: '15px'}}>{t('register_username')}</p>
                                <input id="username" className="inputtext" type="text" maxLength="24" placeholder="Username"/>

                                <p className="pre-title fadeout" style={{marginTop: '10px', marginBottom: '15px'}}>{t('register_email')}</p>
                                <input id="email" className="inputtext" type="email" maxLength="32" placeholder="account@gmail.com"/>

                                <br/><br/><br/>
                                <p className="pre-title fadeout" style={{marginTop: '10px', marginBottom: '15px'}}>{t('register_password_1')}</p>
                                <input id="password1" className="inputtext" type="password" maxLength="32" placeholder="*************"/>

                                <p className="pre-title fadeout" style={{marginTop: '10px', marginBottom: '15px'}}>{t('register_password_2')}</p>
                                <input id="password2" className="inputtext" type="password" maxLength="32" placeholder="*************"/>

                                <br/><br/><br/>
                                <p className="pre-title fadeout" style={{marginTop: '10px', marginBottom: '15px'}}>{t('register_invite')}</p>
                                <input id="promo" className="inputtext" type="text" maxLength="16" placeholder={t('register_invite_2')}/>

                                <br/><br/><br/>
                                <center>
                                    <Link to="">{t('register_privacy')}</Link>

                                    <br/><br/>
                                    <Turnstile ref={captcha_ref} siteKey="0x4AAAAAAA9t0jFDTrvHLD0W" key={key}/>
                                </center>

                                <br/><br/>
                                <div className="go-up fadeout">
                                    <p id="auth-button1" className="btn clickable" onClick={StartRegister}><span>{t('register_create')}</span></p>
                                    <p id="auth-button2" className="btn" style={{display: "none"}}><span>...</span></p>

                                    <Link to="/auth" className="btn clickable" style={{marginLeft: 'auto', marginRight: '0'}}><span>{t('register_already')}</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/><br/>

                </div>
            </div>
        </section>

        {/*<Footer/>*/}
    </>
    );
};
export default Register;