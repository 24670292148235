import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import {ShowError} from '../Modules/Popups';

const FooterRefund = (props) => {
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const isHover = e => e.parentElement.querySelector(':hover') === e;
    function Close() {
        document.getElementById('modal-wrapper').classList.remove('show');
        document.getElementById('modal-wrapper').classList.remove('fadeout');
        document.getElementById('modal').classList.remove('show');

        //document.getElementById('modals').removeChild(document.getElementById('modal-wrapper'));
    }
    function CloseFull() {
        const hovered = isHover(document.getElementById('modal'));
        if (!hovered)
            Close();
    }
    function Show() {
        ReactDOM.render(<FooterRefund identifier={props.identifier} name={props.name}/>, document.getElementById('modals'));

        setTimeout(() => {
            document.getElementById('modal-wrapper').onclick = CloseFull;
            document.getElementById('modal-wrapper').classList.add('show');
            document.getElementById('modal-wrapper').classList.add('fadeout');
            document.getElementById('modal').classList.add('show');
            document.getElementById('body-loading').style.display = "block";
            //document.getElementById('body').style.display = "none";
            document.getElementById('close-refund-button').onclick = Close;
        }, 200);
    }

    return (
    <>
        <div id="modal-wrapper" className="modal-wrapper" onClick={CloseFull}>
            <div id="modal" className="modal cash-up">

                <div id="body-loading" className="body" style={{ maxHeight: "80vh", 'overflow': 'auto' }}>

                    <div style={{ display: "flex", alignItems: "center" }}>
                        <p className="pre-title2 fadeout">{t('refund_title')}</p>
                        <a style={{ marginLeft: "auto" }}><div id="close-refund-button" className="pre-title2" style={{fontSize: '30px', 'cursor': 'pointer'}} onClick={Close}>x</div></a>
                    </div>


                    <div className="hl"></div>
                    <ul className="pre-text fadeout">
                        <li>
                        {t('refund_description_1_1')}<br/>
                        {t('refund_description_1_2')}<br/>
                        {t('refund_description_1_3')}<br/>
                        </li>
                        
                        <br/><li>
                        {t('refund_description_2_1')}<br/>
                        {t('refund_description_2_2')}<br/>
                        {t('refund_description_2_3')}<br/>
                        </li>
                        
                        <br/><li>
                        {t('refund_description_3_1')}<br/>
                        </li>

                        <br/><br/>
                        {t('refund_description_4_1')}<br/>
                        {t('refund_description_4_2')}<br/>

                        <br/><br/>
                        {t('refund_date')}
                    </ul>
                </div>

            </div>
        </div>

        <a className="clickable" onClick={() => Show()}>{t('footer_refund')}</a>
    </>
    );
};
export default FooterRefund;