import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import CryptoJS from 'crypto-js';

import axios from 'axios';
import qs from 'qs';

import Popups, { ShowSuccess, ShowError } from './Modules/Popups';

import Account_Header from './Modules/Account_Header';
import Account_Navigation from './Modules/Account_Navigation';
import AccountSupporterMessages from './AccountSupporterMessages';
import AccountSupporter_Events from './Modals/AccountSupporter_Events';

import loading_image from '../images/loading2.gif';
import empty_image from "../images/empty.png";
import image_logo from '../images/logo.png';


const AccountSupporter = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);

    const [InvalidToken, SetInvalidToken] = React.useState(false);

    const [Id, SetId] = React.useState(0);
    const [Username, SetUsername] = React.useState("Username");
    const [Avatar, SetAvatar] = React.useState("Avatar");
    const [Balance, SetBalance] = React.useState(0);

    const [CurrentTicket, SetCurrentTicket] = React.useState(0);
    const [CurrentTicketStatus, SetCurrentTicketStatus] = React.useState(0);
    const [CurrentTicketLastAnswer, SetCurrentTicketLastAnswer] = React.useState("");

    const [newMessage, SetNewMessage] = React.useState("");
    const [CurrentSort, SetCurrentSort] = React.useState("");

    const [TicketsParsed, SetTicketsParsed] = React.useState(false);
    const [TicketsArray, SetTicketsArray] = React.useState([]);

    function decrypt(encryptedData) {
        const encryptedBytes = atob(encryptedData);
        const keyBytes = unescape(encodeURIComponent("MyEncryptionKey123"));

        const decryptedBytes = new Uint8Array(encryptedBytes.length);

        for (let i = 0; i < encryptedBytes.length; i++) {
            const encryptedByte = encryptedBytes.charCodeAt(i);
            const keyByte = keyBytes.charCodeAt(i % keyBytes.length);

            // Пропускаем символы, не соответствующие ASCII
            if (encryptedByte > 255 || keyByte > 255) {
                decryptedBytes[i] = encryptedByte;
            } else {
                decryptedBytes[i] = encryptedByte ^ keyByte;
            }
        }

        const decoder = new TextDecoder('utf-8');
        const decryptedString = decoder.decode(decryptedBytes);

        return JSON.parse(decryptedString);
    }
    function GetHwid() {
        var browser = navigator.hardwareConcurrency + "," + navigator.languages; //navigator.userAgent
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }
    
    function SetUrl(paramName, newValue) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(paramName, newValue); // Устанавливаем или заменяем значение

        let newUrl = window.location.pathname; 
        if (searchParams.toString() !== "") {
            newUrl += "?" + searchParams.toString(); // Добавляем параметры, если есть
        }
        window.history.replaceState({}, '', newUrl);
    }
    function GetParameterUrl(paramName) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(paramName);
    }

    useEffect(()=> {
        if (cookies.balance != null) SetBalance(cookies.balance);
        
        axios.post('https://api.0xcheats.net/Account/Supporter/Info', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid() }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);
            
            if (json.success == "true")
            {
                SetId(json.id);
                SetUsername(json.username);

                if (json.avatar.length == 0)
                    SetAvatar("https://api.0xcheats.net/Images/Avatars/invalid.jpg");
                else
                    SetAvatar("https://api.0xcheats.net/Images/Avatars/" + json.avatar);

                SetBalance(json.balance);
                setCookie("balance", json.balance, { path: "/" });
            }
            else if (json.failed != null)
            {
                SetInvalidToken(true);
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {

        });

        if (!TicketsParsed) {
            ParseTickets();

            setInterval(() => {
                console.log("Parsing tickets...");
                ParseTickets();
            }, 30000);
            
            window.addEventListener('focus', function() {
                titleState = 0;
                clearInterval(intervalId);
                document.title = "0xCheats.net";
            });

            let openedTicket = GetParameterUrl("ticket");
            if (openedTicket != null) {
                OpenTicket(openedTicket, "", "");
            }
        }

    }, [])
    if (InvalidToken) {
        removeCookie("token", { path: "/" });
        return <Navigate to="/auth" replace />;
    }

    let unansweredCount = 0;
    let intervalId;
    let titleState = 0;
    function ParseTickets() {
        axios.post('https://api.0xcheats.net/Account/Supporter/Tickets', qs.stringify({ 'token': cookies.token }))
        .then(function(response) {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);
            
            if (json.success == "true") {
                SetTicketsParsed(true);
                SetTicketsArray(json.tickets);

                // intervalId = setInterval(() => {
                //     if (titleState == 0) {
                //         titleState = 1;
                //         document.title = "🌑 НОВЫЙ ТИКЕТ 💡";
                //     }
                //     else if (titleState == 1) {
                //         titleState = 2;
                //         document.title = "🌒 НОВЫЙ ТИКЕТ";
                //     }
                //     else if (titleState == 2) {
                //         titleState = 3;
                //         document.title = "🌓 НОВЫЙ ТИКЕТ 💡";
                //     }
                //     else if (titleState == 3) {
                //         titleState = 4;
                //         document.title = "🌔 НОВЫЙ ТИКЕТ";
                //     }
                //     else if (titleState == 4) {
                //         titleState = 5;
                //         document.title = "🌕 НОВЫЙ ТИКЕТ 💡";
                //     }
                //     else if (titleState == 5) {
                //         titleState = 6;
                //         document.title = "🌖 НОВЫЙ ТИКЕТ";
                //     }
                //     else if (titleState == 6) {
                //         titleState = 7;
                //         document.title = "🌗 НОВЫЙ ТИКЕТ 💡";
                //     }
                //     else if (titleState == 7) {
                //         titleState = 8;
                //         document.title = "🌘 НОВЫЙ ТИКЕТ";
                //     }
                //     else if (titleState == 8) {
                //         titleState = 1;
                //         document.title = "🌑 НОВЫЙ ТИКЕТ 💡";
                //     }
                // }, 500);
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            
        });
    }

    function Ticket(ticket) {

        //Неотвеченные
        if (CurrentSort == "") {
            if (ticket.status == 1 && ticket.last_answer == "user") {
                return (
                    <div id={"ticket" + ticket.id} key={ticket.id} className={`${ticket.status == 1 ? "ticket" : ticket.status == 0 ? "ticket close" : ticket.status == 2 ? "ticket admin" : ticket.status == 3 ? "ticket refund" : "ticket"} ${CurrentTicket == ticket.id ? "active" : ""} `} onClick={() => OpenTicket(ticket.id, ticket.name, ticket.type, ticket.status, ticket.last_answer)}>
                        <p className="status-view">{ ticket.status == 0 ? "Закрыт" : ticket.status == 1 ? "Открыт" : ticket.status == 2 ? "Админ" : ticket.status == 3 ? "Возврат" : "Закрыт" }</p>
                        <div>
                            <p className="name">{ticket.name}</p>
                            <span>{ticket.type}</span>
                        </div>
                        <p className="last-reply">{ticket.last_answer_date}</p>
                    </div>
                );
            }
        }
        //Отвеченные
        if (CurrentSort == " answered") {
            if (ticket.status == 1 && ticket.last_answer == "support") {
                return (
                    <div id={"ticket" + ticket.id} key={ticket.id} className={`${ticket.status == 1 ? "ticket" : ticket.status == 0 ? "ticket close" : ticket.status == 2 ? "ticket admin" : ticket.status == 3 ? "ticket refund" : "ticket"} ${CurrentTicket == ticket.id ? "active" : ""} `} onClick={() => OpenTicket(ticket.id, ticket.name, ticket.type, ticket.status, ticket.last_answer)}>
                        <p className="status-view">{ ticket.status == 0 ? "Закрыт" : ticket.status == 1 ? "Открыт" : ticket.status == 2 ? "Админ" : ticket.status == 3 ? "Возврат" : "Закрыт" }</p>
                        <div>
                            <p className="name">{ticket.name}</p>
                            <span>{ticket.type}</span>
                        </div>
                        <p className="last-reply">{ticket.last_answer_date}</p>
                    </div>
                );
            }
        }
        //Админ
        if (CurrentSort == " admin") {
            if (ticket.status == 2) {
                return (
                    <div id={"ticket" + ticket.id} key={ticket.id} className={`${ticket.status == 1 ? "ticket" : ticket.status == 0 ? "ticket close" : ticket.status == 2 ? "ticket admin" : ticket.status == 3 ? "ticket refund" : "ticket"} ${CurrentTicket == ticket.id ? "active" : ""} `} onClick={() => OpenTicket(ticket.id, ticket.name, ticket.type, ticket.status, ticket.last_answer)}>
                        <p className="status-view">{ ticket.status == 0 ? "Закрыт" : ticket.status == 1 ? "Открыт" : ticket.status == 2 ? "Админ" : ticket.status == 3 ? "Возврат" : "Закрыт" }</p>
                        <div>
                            <p className="name">{ticket.name}</p>
                            <span>{ticket.type}</span>
                        </div>
                        <p className="last-reply">{ticket.last_answer_date}</p>
                    </div>
                );
            }
        }
        //Возврат
        if (CurrentSort == " refund") {
            if (ticket.status == 3) {
                return (
                    <div id={"ticket" + ticket.id} key={ticket.id} className={`${ticket.status == 1 ? "ticket" : ticket.status == 0 ? "ticket close" : ticket.status == 2 ? "ticket admin" : ticket.status == 3 ? "ticket refund" : "ticket"} ${CurrentTicket == ticket.id ? "active" : ""} `} onClick={() => OpenTicket(ticket.id, ticket.name, ticket.type, ticket.status, ticket.last_answer)}>
                        <p className="status-view">{ ticket.status == 0 ? "Закрыт" : ticket.status == 1 ? "Открыт" : ticket.status == 2 ? "Админ" : ticket.status == 3 ? "Возврат" : "Закрыт" }</p>
                        <div>
                            <p className="name">{ticket.name}</p>
                            <span>{ticket.type}</span>
                        </div>
                        <p className="last-reply">{ticket.last_answer_date}</p>
                    </div>
                );
            }
        }
        //Без фильтра
        if (CurrentSort == "0") {
            return (
                <div id={"ticket" + ticket.id} key={ticket.id} className={`${ticket.status == 1 ? "ticket" : ticket.status == 0 ? "ticket close" : ticket.status == 2 ? "ticket admin" : ticket.status == 3 ? "ticket refund" : "ticket"} ${CurrentTicket == ticket.id ? "active" : ""} `} onClick={() => OpenTicket(ticket.id, ticket.name, ticket.type, ticket.status, ticket.last_answer)}>
                    <p className="status-view">{ ticket.status == 0 ? "Закрыт" : ticket.status == 1 ? "Открыт" : ticket.status == 2 ? "Админ" : ticket.status == 3 ? "Возврат" : "Закрыт" }</p>
                    <div>
                        <p className="name">{ticket.name}</p>
                        <span>{ticket.type}</span>
                    </div>
                    <p className="last-reply">{ticket.last_answer_date}</p>
                </div>
            );
        }

        return (
            <>
            </>
        );
    }
    function Tickets() {
        if (TicketsParsed) {
            return TicketsArray.map((ticket) =>
                <Ticket key={ticket.id} id={ticket.id} status={ticket.status} name={ticket.name} type={ticket.type} last_answer={ticket.last_answer} last_answer_date={ticket.last_answer_date} />
            );
        }
        else {
            return (
            <>
                <div className="loading">
                    <div><br/><br/><br/><br/></div>
                </div>
                <br/><br/>

                <div className="loading">
                    <div><br/><br/><br/><br/></div>
                </div>
                <br/><br/>

                <div className="loading">
                    <div><br/><br/><br/><br/></div>
                </div>
                <br/><br/>
            </>
            );
        }
    }

    var currentTicket = 0;
    function OpenTicket(ticket, title, description, status, last_answer) {

        currentTicket = ticket;
        SetCurrentTicket(ticket);
        SetCurrentTicketStatus(status);
        SetCurrentTicketLastAnswer(last_answer);
        SetNewMessage("");

        SetUrl("ticket", ticket);

        document.getElementById("ticket_body").style.display = "block";

        document.getElementById("ticket_title").textContent = title;
        document.getElementById("ticket_description").textContent = description;
        document.getElementById("ticket_id").textContent = "#" + ticket;

        //Тикет
        let foundTicket = document.getElementById('ticket' + ticket);
        if (foundTicket != null) {
            //Если тикет закрыт
            if (foundTicket.classList.contains('close'))
            {
                document.getElementById("ticket_send_message").style.display = "none";
                document.getElementById("ticket_send_message_closed").style.display = "flex";

                document.getElementById("ticket_close_text").style.display = "none";
                document.getElementById("ticket_close_button").style.display = "none";
            }
            else {
                document.getElementById("ticket_send_message").style.display = "flex";
                document.getElementById("ticket_send_message_closed").style.display = "none";

                document.getElementById("ticket_close_text").style.display = "block";
                document.getElementById("ticket_close_button").style.display = "flex";
            }
        }
        else {
            //Тикет не найден (возможно только создан)
            document.getElementById("ticket_send_message").style.display = "flex";
            document.getElementById("ticket_send_message_closed").style.display = "none";

            document.getElementById("ticket_close_text").style.display = "block";
            document.getElementById("ticket_close_button").style.display = "flex";
        }
    }
    function LeaveTicket() {

        currentTicket = 0;
        SetCurrentTicket(0);

        document.getElementById("ticket_body").style.display = "none";
    }

    function Sort(status) {

        let current = document.getElementById('sort' + CurrentSort);
        if (current != null) current.classList.remove("active");

        if (CurrentSort == status) {
            SetCurrentSort("0");
        }
        else {
            document.getElementById('sort' + status).classList.add("active");
            SetCurrentSort(status);
        }

        //if (ticket != null) ticket.classList.add('active');
    }

    function RestoreSortAndTicket() {

        Sort(CurrentSort);

        //Ticket
        let ticket = document.getElementById('ticket' + currentTicket);
        if (ticket != null) ticket.classList.add('active');

        //Sort
        let current = document.getElementById('sort' + CurrentSort);
        if (current != null) current.classList.add("active");
    }

    function CreateNewMessage() {
        let message = document.getElementById('ticket_new_message').value;

        message = message.trim();

        //Проверяем поля
        if (message.length < 1) {
            ShowError("Сообщение должно быть больше одного символа!");
            return;
        }

        //Скрываем кнопку отправки сообщения
        document.getElementById('create_message_button').style.display = "none";
        document.getElementById('create_message_button2').style.display = "flex";

        axios.post('https://api.0xcheats.net/Account/Supporter/Ticket/NewMessage', qs.stringify({ 'token': cookies.token, 'ticket': CurrentTicket, 'message': message }))
        .then(function(response) {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);

            if (json.success == "true") {
                //Обновляем список сообщений
                SetNewMessage(message);
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            
        });
    }


    return (
    <>
        <Popups/>
        <section id="tickets" className="profile-page">
            <Account_Navigation active={"help"}/>
            
            <div className="container">
                <div className="content">
                    <Account_Header username={Username} id={Id} avatar={Avatar} balance={Balance}/>
                    <div className="hl-reson"></div>
                    <div className="pre-title no-m fadeout"><i className="icon support2"></i>Агент поддержки</div>

                    <div className="split">
                        <div id="tickets_list" className="tickets">
                            <div className="sort-block fadeout">
                                <div className="sort-wrapper" onClick={() => Sort("")}><p id="sort" className="sort no-reply active">Неотвеченные</p></div>
                                <div className="sort-wrapper" onClick={() => Sort(" answered")}><p id="sort answered" className="sort close">Отвеченные</p></div>
                                <div className="sort-wrapper" onClick={() => Sort(" admin")}><p id="sort admin" className="sort admin">Админ</p></div>
                                <div className="sort-wrapper" onClick={() => Sort(" refund")}><p id="sort refund" className="sort refund">Возврат</p></div>
                            </div>
                            <Tickets/>
                        </div>

                        <div className="vr-repos"></div>

                        {/*<div id="create_new" className="create-ticket-block fadeout">
                            <div className="warning"><i className="icon question" style={{paddingRight:"25px"}}></i><font color="white">Прежде чем создавать тикет убедитесь что вашего вопроса нет в FAQ</font></div>
                            <div className="form-create">
                                <div className="type">
                                    <p>Выберите тип обращения</p>
                                    <select id="create_new_select" type="text" placeholder="Общие вопросы">
                                        <option value="0">Общие вопросы</option>
                                        <option value="1">Идеи и предложения</option>
                                        <option value="2">Сотрудничество</option>
                                    </select>
                                </div>
                                <input id="create_new_title" type="text" placeholder="Введите название обращения" maxLength="24"/>
                                <textarea id="create_new_body" placeholder="Введите сообщение..."></textarea>
                                <a id="create_ticket_button" className="btn black clickable fadeout"><i className="icon plus"></i>Создать обращение</a>
                            </div>
                        </div>*/}

                        <div id="ticket_body" className="ticket-messanger fadeout" style={{display: "none"}}>
                            <div className="header">
                                <div className="info">
                                    <p className="theme"><i className="icon ticket"></i></p>
                                    <p className="more">
                                        <span id="ticket_title" className="id">Ticket #0</span>
                                        <span id="ticket_description">Тестовый</span>
                                        <span id="ticket_id">#0</span>
                                    </p>
                                </div>
                                <div className="actions">
                                    <span id="ticket_close_text" style={{color:'#000'}}>Возможные действия</span>
                                    <AccountSupporter_Events token={cookies.token} ticket={CurrentTicket}/>
                                    {/*<p id="ticket_close_button" onClick={() => CloseTicket()}><i className="icon ticket_more2"></i></p>*/}
                                    <p><i className="icon ticket_leave" onClick={LeaveTicket}></i></p>
                                </div>
                            </div>

                            <div id="ticket_messages" className="chat-window fadeout">
                                <AccountSupporterMessages ticket={CurrentTicket}  message={newMessage}/>
                            </div>

                            <div id="ticket_send_message" className="send-msg">
                                <textarea id="ticket_new_message" className="accept-icon-input" placeholder="Введите сообщение"></textarea>
                                {/*<div className="" contenteditable="true" placeholder="Введите сообщение"></div>*/}
                                {/*<a href="javascript:void(0)" className="emoji-button" title="Emojis"><i className="icon smile"></i></a>*/}
                                {/*<p className="files"><i className="icon attach"></i></p>*/}
                                <a id="create_message_button" className="btn black send" onClick={() => CreateNewMessage()}><i className="icon send"></i></a>
                                <a id="create_message_button2" className="btn black send" style={{display: "none"}}><img src={loading_image} width='168'/></a>
                            </div>
                            <div id="ticket_send_message_closed" className="send-msg-closed" style={{display: "none"}}>
                                <p>Обращение закрыто. Ответ невозможен.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </>
    );
};
export default AccountSupporter;