import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import image_logo from '../images/logo.png';

import FooterPrivacy from './Modals/FooterPrivacy';
import FooterRefund from './Modals/FooterRefund';
import FooterTerms from './Modals/FooterTerms';


const Footer = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); }; const getLanguage = () => { return i18n.language; };

    function GetLang() {
        const language = navigator.language || navigator.userLanguage;
        //console.log(language); // Выведет язык в формате "en-US", "ru-RU", "zh-CN" и т.д.
        return language.split('-')[0];
    }

    useEffect(()=> {
        setTimeout(() => {
            if (cookies.lang == null) {
                let lang = GetLang();
                changeLanguage(lang);
                setCookie("lang", lang, { path: "/" });
            }
            else {
                changeLanguage(cookies.lang);
            }
        }, 100);
    }, [])

    function SelectLanguage(lang) {
        changeLanguage(lang);
        setCookie("lang", lang, { path: "/" });
    }


    return (
    <footer className="fadeout">
        <div className="container">
            <div className="content">
                <div className="main-info">
                    <a href="" className="logo">
                        <img src={image_logo} alt=""/>
                        <span className="info">
                            <span className="name">0xcheats</span>
                            <span>private</span>
                        </span>
                    </a>
                    <div className="links">
                        <div>
                            <FooterPrivacy/>
                            <FooterRefund/>
                            <FooterTerms/>
                        </div>
                        <div>
                            <a href="https://vk.com/0xcheats" target="_blank">{t('footer_vk')}</a>
                            {/*<a href="https://t.me/zeroxcommunity" target="_blank">{t('footer_telegram')}</a>*/}
                            <a href="https://discord.gg/0x-community" target="_blank">{t('footer_discord')}</a>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <p>{t('footer_design')} <a>Ⓐ AdamS</a> &amp; {t('footer_layout')} <a>䖶 Hyuga</a> &amp; {t('footer_backend')} <a>Superior.</a></p>
                    <span style={{'opacity': '0.4'}}>{t('footer_layzan')}</span>
                    <br/>
                    <div className="footer_language_container">
                        <p id="lang-ru" className={`footer_language ${getLanguage() === 'ru' ? 'active' : ''}`} onClick={() => SelectLanguage("ru")}>Русский</p>
                        <p id="lang-en" className={`footer_language ${getLanguage() === 'en' ? 'active' : ''}`} onClick={() => SelectLanguage("en")}>English</p>
                        <p id="lang-cn" className={`footer_language ${getLanguage() === 'zh' ? 'active' : ''}`} onClick={() => SelectLanguage("zh")}>中文</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    );
};
export default Footer;