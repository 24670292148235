import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from './Header';
import Footer from './Footer';
import Index_Popular from './Modules/Index_Popular';
import Index_Faq from './Modules/Index_Faq';

import Popups, {ShowError} from './Modules/Popups';

import image_sot from '../images/block-sea-of-thieves.png';
import image_gta from '../images/block-gtav.png';


const Faq = () => {
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    useEffect(()=>
    {
        document.getElementById("_faq").className = "active";
    }, [])

    return (
    <>
        <Popups/>

        <Header/>

        <Index_Faq/>

        <Index_Popular/>

        <section id="advantages" className="fadeout">
            <div className="container">
                <div className="content">
                    <p className="title">{t('index_advantages')}</p>
                    <div className="carousel">

                        <div className="block-wrapper dynamic">
                            <div className="block">
                                <div className="header">
                                    <div className="icon-wrapper"><i className="icon pc"></i></div>
                                    <p className="name">{t('index_advantages_1_title')}</p>
                                </div>
                                <div className="hl"></div>
                                <div className="body">{t('index_advantages_1_description')}</div>
                            </div>
                        </div>

                        <div className="block-wrapper dynamic">
                            <div className="block">
                                <div className="header">
                                    <div className="icon-wrapper"><i className="icon dev"></i></div>
                                    <p className="name">{t('index_advantages_2_title')}</p>
                                </div>
                                <div className="hl"></div>
                                <div className="body">{t('index_advantages_2_description')}</div>
                            </div>
                        </div>

                        <div className="block-wrapper dynamic">
                            <div className="block">
                                <div className="header">
                                    <div className="icon-wrapper"><i className="icon support"></i></div>
                                    <p className="name">{t('index_advantages_3_title')}</p>
                                </div>
                                <div className="hl"></div>
                                <div className="body">{t('index_advantages_3_description')}</div>
                            </div>
                        </div>

                        <div className="block-wrapper dynamic">
                            <div className="block">
                                <div className="header">
                                    <div className="icon-wrapper"><i className="icon security"></i></div>
                                    <p className="name">{t('index_advantages_4_title')}</p>
                                </div>
                                <div className="hl"></div>
                                <div className="body">{t('index_advantages_4_description')}</div>
                            </div>
                        </div>

                    </div>
                    <div className="arrows-action">
                        <div className="arrow left">
                            <i className="icon arrow-left-big"></i>
                        </div>
                        <div className="arrow right active">
                            <i className="icon arrow-right-big"></i>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer/>
    </>
    );
};
export default Faq;