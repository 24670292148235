import React, { useEffect } from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { curveCardinal } from 'd3-shape';
import { useCookies } from 'react-cookie';

import axios from 'axios';
import qs from 'qs';

import {ShowError} from '../Modules/Popups';


const AccountPartner_Stats = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const [Parsed, SetParsed] = React.useState(false);
    const [myData, setMyData] = React.useState([]);
    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FF2042"];

    function GetHwid() {
        var browser = navigator.hardwareConcurrency + "," + navigator.languages; //navigator.userAgent
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }

    function addValue(name, value) {
      setMyData(prevData => [...prevData, { name, value }]);
    }

    useEffect(()=> {
        axios.post('https://api.0xcheats.net/Account/Partner/Stats', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid() }))
        .then(function(response) {
              const data = JSON.stringify(response.data);
              const json = JSON.parse(data);

              if (json.success == "true") {
                  const current = JSON.parse(JSON.stringify(json.current));
                  const newData = current.filter(item => item.name.indexOf(':') === -1).map(item => ({ name: item.name, value: item.value }));

                  setMyData(newData);
                  SetParsed(true);
              }
              else if (json.failed != null) {
                ShowError(json.failed);
              }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            
        });
    }, [])

    if (Parsed) {
        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
          const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
          const x = cx + radius * Math.cos(-midAngle * RADIAN);
          const y = cy + radius * Math.sin(-midAngle * RADIAN);

          return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
              {`${(percent * 100).toFixed(0)}%`}
            </text>
          );
        };

        const cardinal = curveCardinal.tension(0.2);

        return (
            <ResponsiveContainer width="100%" height="100%">

              <PieChart width={500} height={500}>
                <Pie
                  data={myData}
                  cx={100}
                  cy={100}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                  margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 45,
                }}
                >
                  {myData.map((entry, index) => ( <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />))}
                </Pie>
                <Tooltip/>
              </PieChart>

            </ResponsiveContainer>
        );
    }
};
export default AccountPartner_Stats;