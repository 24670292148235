import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useCookies } from 'react-cookie';

import axios from 'axios';
import qs from 'qs';

import { ShowError, ShowSuccess } from '../Modules/Popups';

import loading_image from '../../images/loading2.gif';


const AccountCoder_Upload = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);

    const [selectedFile, setSelectedFile] = useState("");
    const [selectedFileOther, setSelectedFileOther] = useState("");

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.name.endsWith('.dll'))
        {
            setSelectedFile(file);
        }
        else
        {
            setSelectedFile("");
        }
    };
    const handleFileOtherChange = (event) => {
        const file = event.target.files[0];
        if (file)
        {
            setSelectedFileOther(file);
        }
        else
        {
            setSelectedFileOther("");
        }
    };
    let useVMProtect = false;

    function ParseVersions() {
        axios.post('https://api.0xcheats.net/Account/Coder/Hack/Versions', qs.stringify({ 'token': props.token, 'identifier': props.identifier }))
        .then(function(response)
        {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);
            
            if (json.success == "true")
            {
                document.getElementById('body-loading').style.display = "none";
                document.getElementById('body').style.display = "block";
                document.getElementById('upload-body').style.display = "none";

                //Очищаем и обновляем список версий
                ClearVersionsList();

                if (json.uploaded != null) {
                    const versionObject = document.createElement('div');
                    versionObject.id = "current";
                    versionObject.className = 'radio-box active';
                    versionObject.addEventListener('click', () => SelectVersion("current"));

                    const versionInfo = document.createElement('p');
                    versionInfo.textContent = json.uploaded.version + ' | Загружено '+ json.uploaded.date +' в ' + json.uploaded.time;
                    versionObject.appendChild(versionInfo);

                    const versionSize = document.createElement('p');
                    versionSize.textContent = '[' + (json.uploaded.size/(1024*1024)).toFixed(2) + 'MB]';
                    versionInfo.appendChild(versionSize);

                    const versionCheck = document.createElement('div');
                    versionCheck.className = 'radio';
                    versionObject.appendChild(versionCheck);

                    const versionCheckSpan = document.createElement('span');
                    versionCheck.appendChild(versionCheckSpan);

                    document.getElementById('current-version').appendChild(versionObject);
                }

                json.versions.forEach(function(version) {
                    const versionObject = document.createElement('div');
                    versionObject.id = version.version;
                    versionObject.className = 'radio-box';
                    versionObject.addEventListener('click', () => SelectVersion(version.version));
                    //versionObject.addEventListener('click', SelectVersion.bind(null, version.version));

                    const versionInfo = document.createElement('p');
                    versionInfo.textContent = version.version + ' | Загружено '+ version.date +' в ' + version.time;
                    versionObject.appendChild(versionInfo);

                    const versionSize = document.createElement('p');
                    versionSize.textContent = '[' + (version.size/(1024*1024)).toFixed(2) + 'MB]';
                    versionInfo.appendChild(versionSize);

                    const versionCheck = document.createElement('div');
                    versionCheck.className = 'radio';
                    versionObject.appendChild(versionCheck);

                    const versionCheckSpan = document.createElement('span');
                    versionCheck.appendChild(versionCheckSpan);

                    document.getElementById('versions').appendChild(versionObject);
                });
            }
            else if (json.failed !== null)
            {
                ShowError(json.failed);
            }
        })
        .catch(function(error)
        {
            ShowError(error);
        })
        .finally(function()
        {
            
        });
    }
    function ParseFiles() {
        axios.post('https://api.0xcheats.net/Account/Coder/Hack/Files', qs.stringify({ 'token': props.token, 'identifier': props.identifier }))
        .then(function(response)
        {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);
            
            if (json.success == "true")
            {
                document.getElementById('files-body-loading').style.display = "none";
                document.getElementById('files-body').style.display = "block";
                document.getElementById('files-upload-body').style.display = "none";

                //Очищаем и обновляем список версий
                ClearFilesList();

                json.files.forEach(function(file) {
                    const versionObject = document.createElement('div');
                    versionObject.id = file.name;
                    versionObject.className = 'radio-box';
                    versionObject.addEventListener('click', () => SelectFile(file.name));

                    const versionInfo = document.createElement('p');
                    versionInfo.textContent = file.name + ' | Загружено '+ file.date +' в ' + file.time;
                    versionObject.appendChild(versionInfo);

                    const versionSize = document.createElement('p');
                    versionSize.textContent = '[' + (file.size/(1024*1024)).toFixed(2) + 'MB]';
                    versionInfo.appendChild(versionSize);

                    const versionCheck = document.createElement('div');
                    versionCheck.className = 'radio';
                    versionObject.appendChild(versionCheck);

                    const versionCheckSpan = document.createElement('span');
                    versionCheck.appendChild(versionCheckSpan);

                    document.getElementById('files').appendChild(versionObject);
                });
            }
            else if (json.failed !== null)
            {
                ShowError(json.failed);

                document.getElementById('files-body-loading').style.display = "block";
                document.getElementById('files-body').style.display = "none";
            }
        })
        .catch(function(error)
        {
            ShowError(error);
        })
        .finally(function()
        {
            
        });
    }

    function SelectVersion(version) {
        //Отключаем все галочки
        document.getElementById("current").classList.remove('active');
        const active = document.getElementById("versions").querySelector('.active');
        if (active)
            active.classList.remove('active');

        //Включаем выбранную галочку
        document.getElementById(version).classList.add('active');
    }
    function ClearVersionsList() {
        const current_version = document.getElementById('current-version');
        while (current_version.firstChild)
            current_version.removeChild(current_version.firstChild);

        const versions = document.getElementById('versions');
        while (versions.firstChild)
            versions.removeChild(versions.firstChild);
    }

    function SelectFile(file) {
        //Отключаем все галочки
        document.getElementById("current").classList.remove('active');
        const active = document.getElementById("files").querySelector('.active');
        if (active)
            active.classList.remove('active');

        //Включаем выбранную галочку
        document.getElementById(file).classList.add('active');
    }
    function ClearFilesList() {
        const versions = document.getElementById('files');
        while (versions.firstChild)
            versions.removeChild(versions.firstChild);
    }

    function UploadVersion() {
        let identifier = document.getElementById('upload-identifier').value;
        let vmprotect = document.getElementById('upload-vmprotect').value;
        let version = document.getElementById('upload-version').value;

        document.getElementById("upload-versions-button").style.display = 'none';
        document.getElementById("upload-versions-button2").style.display = 'flex';

        if (version.length === 0)
        {
            document.getElementById("upload-versions-button").style.display = 'flex';
            document.getElementById("upload-versions-button2").style.display = 'none';
            
            ShowError("Название версии не может быть пустым!");
            return;
        }

        const formData = new FormData();
        formData.append('token', cookies.token);
        formData.append('identifier', identifier);
        formData.append('file', selectedFile);
        formData.append('version', version);
        formData.append('vmprotect', vmprotect);

        axios.post('https://api.0xcheats.net/Account/Coder/Hack/Versions/Upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(function(response)
        {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);
            
            if (json.success == "true")
            {
                ShowSuccess("Новая версия была успешно загружена!");
            }
            else if (json.failed != null)
            {
                ShowError(json.failed);
            }
        })
        .catch(function(error)
        {
            ShowError(error);
        })
        .finally(function()
        {
            document.getElementById("upload-versions-button").style.display = 'flex';
            document.getElementById("upload-versions-button2").style.display = 'none';
        });
    }
    function UploadNewVersionShow() {
        document.getElementById('body').style.display = "none";
        document.getElementById('upload-body').style.display = "block";
    }
    function UploadNewVersionHide() {
        document.getElementById('body-loading').style.display = "block";
        document.getElementById('body').style.display = "none";
        document.getElementById('upload-body').style.display = "none";

        ParseVersions();
    }

    function ReleaseVersion() {
        const active = document.querySelector("#versions .active");
        if (active)
        {
            document.getElementById('body-loading').style.display = "block";
            document.getElementById('body').style.display = "none";

            axios.post('https://api.0xcheats.net/Account/Coder/Hack/Versions/Release', qs.stringify({ 'token': props.token, 'identifier': props.identifier, 'version': active.id }))
            .then(function(response)
            {
                const data = JSON.stringify(response.data);
                const json = JSON.parse(data);
                
                if (json.success == "true")
                {
                    //Обновляем список версий
                    ParseVersions();
                }
                else if (json.failed !== null)
                {
                    ShowError(json.failed);
                }
            })
            .catch(function(error)
            {
                ShowError(error);
            })
            .finally(function()
            {
                
            });
        }
        else
        {
            ShowError("Выбранная версия уже в релизе!");
        }
    };
    function DeleteVersion() {
        const active = document.querySelector("#versions .active");
        if (active)
        {
            document.getElementById('body-loading').style.display = "block";
            document.getElementById('body').style.display = "none";

            axios.post('https://api.0xcheats.net/Account/Coder/Hack/Versions/Delete', qs.stringify({ 'token': props.token, 'identifier': props.identifier, 'version': active.id }))
            .then(function(response)
            {
                const data = JSON.stringify(response.data);
                const json = JSON.parse(data);
                
                if (json.success == "true")
                {
                    //Обновляем список версий
                    ParseVersions();
                }
                else if (json.failed !== null)
                {
                    ShowError(json.failed);
                }
            })
            .catch(function(error)
            {
                ShowError(error);
            })
            .finally(function()
            {
                
            });
        }
        else
        {
            ShowError("Нельзя удалить активную версию!\nСначала её необходимо изменить.");
        }
    };
    function OtherFiles() {
        document.getElementById('body').style.display = "none";
        document.getElementById('files-body-loading').style.display = "block";

        ParseFiles();
    }
    function OtherFilesBack() {
        document.getElementById('body').style.display = "block";
        document.getElementById('files-body-loading').style.display = "none";
        document.getElementById('files-body').style.display = "none";
    }

    function UploadFile() {
        let identifier = document.getElementById('upload-other-identifier').value;
        let file = document.getElementById('upload-other').value;

        document.getElementById("upload-files-button").style.display = 'none';
        document.getElementById("upload-files-button2").style.display = 'flex';

        if (file.length === 0)
        {
            document.getElementById("upload-files-button").style.display = 'flex';
            document.getElementById("upload-files-button2").style.display = 'none';
            
            ShowError("Название файла не может быть пустым!");
            return;
        }

        const formData = new FormData();
        formData.append('token', cookies.token);
        formData.append('identifier', identifier);
        formData.append('file', selectedFileOther);
        formData.append('name', file);

        axios.post('https://api.0xcheats.net/Account/Coder/Hack/Files/Upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(function(response)
        {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);
            
            if (json.success == "true")
            {
                ShowSuccess("Новый файл был успешно загружен!");
            }
            else if (json.failed != null)
            {
                ShowError(json.failed);
            }
        })
        .catch(function(error)
        {
            ShowError(error);
        })
        .finally(function()
        {
            document.getElementById("upload-files-button").style.display = 'flex';
            document.getElementById("upload-files-button2").style.display = 'none';
        });
    }
    function UploadNewFileShow() {
        document.getElementById('files-body').style.display = "none";
        document.getElementById('files-upload-body').style.display = "block";
    }
    function UploadNewFileHide() {
        document.getElementById('files-body').style.display = "block";
        document.getElementById('files-upload-body').style.display = "none";

        ParseFiles();
    }
    function DeleteFile() {
        const active = document.querySelector("#files .active");
        if (active)
        {
            document.getElementById('files-body-loading').style.display = "block";
            document.getElementById('files-body').style.display = "none";

            axios.post('https://api.0xcheats.net/Account/Coder/Hack/Files/Delete', qs.stringify({ 'token': props.token, 'identifier': props.identifier, 'file': active.id }))
            .then(function(response)
            {
                const data = JSON.stringify(response.data);
                const json = JSON.parse(data);
                
                if (json.success == "true")
                {
                    //Обновляем список версий
                    ParseFiles();
                }
                else if (json.failed !== null)
                {
                    ShowError(json.failed);
                }
            })
            .catch(function(error)
            {
                ShowError(error);
            })
            .finally(function()
            {
                
            });
        }
        else
        {
            ShowError("Нельзя удалить активную версию!\nСначала её необходимо изменить.");
        }
    };


    const isHover = e => e.parentElement.querySelector(':hover') === e;
    function Close() {
        document.getElementById('modal-wrapper').classList.remove('show');
        document.getElementById('modal-wrapper').classList.remove('fadeout');
        document.getElementById('modal').classList.remove('show');
    }
    function CloseFull() {
        const hovered = isHover(document.getElementById('modal'));
        if (!hovered)
            Close();
    }
    function Show() {
        ReactDOM.render(<AccountCoder_Upload identifier={props.identifier} name={props.name}/>, document.getElementById('modals'));

        setTimeout(() => {
            //Очищаем старые данные
            setSelectedFile("");
            setSelectedFileOther("");
            document.getElementById('upload-file').value = "";
            document.getElementById('upload-version').value = "";

            //Запоминаем редактируемый софт
            document.getElementById('upload-vmprotect').value = true;
            document.getElementById('upload-identifier').value = props.identifier;
            document.getElementById('upload-other-identifier').value = props.identifier;

            document.getElementById('modal-wrapper').onclick = CloseFull;
            document.getElementById('modal-wrapper').classList.add('show');
            document.getElementById('modal-wrapper').classList.add('fadeout');
            document.getElementById('modal').classList.add('show');
            document.getElementById('body-loading').style.display = "block";
            document.getElementById('body').style.display = "none";
            document.getElementById('upload-body').style.display = "none";
            document.getElementById('files-body-loading').style.display = "none";
            document.getElementById('files-body').style.display = "none";
            document.getElementById('files-upload-body').style.display = "none";

            document.getElementById('current').classList.add('active');

            document.getElementById('uploadnew-versions-button').onclick = UploadNewVersionShow;
            document.getElementById('goback-versions-button').onclick = UploadNewVersionHide;
            document.getElementById('release-versions-button').onclick = ReleaseVersion;
            document.getElementById('delete-versions-button').onclick = DeleteVersion;

            document.getElementById('upload-use-vmprotect').onclick = () => {
                
                useVMProtect = !useVMProtect;

                if (useVMProtect)
                    document.getElementById('upload-use-vmprotect').classList = "radio-box active";
                else
                    document.getElementById('upload-use-vmprotect').classList = "radio-box";

                document.getElementById('upload-vmprotect').value = useVMProtect;
            };
            //document.getElementById('upload-use-vmprotect').classList.add('active');
            //document.getElementById('upload-versions-button').onclick = UploadVersion;

            document.getElementById('files-button').onclick = OtherFiles;
            document.getElementById('files-back-button').onclick = OtherFilesBack;
            document.getElementById('goback-files-button').onclick = UploadNewFileHide;
            document.getElementById('uploadnew-files-button').onclick = UploadNewFileShow;
            document.getElementById('delete-files-button').onclick = DeleteFile;
            
            ParseVersions();
        }, 200);
    }

    return (
    <>
        <div id="modal-wrapper" className="modal-wrapper" onClick={CloseFull}>
            <div id="modal" className="modal cash-up">
                <div className="header">
                    <img className="avatar" alt="" src={"https://api.0xcheats.net/Images/Games/" + props.identifier +"/logo.jpg"}/>
                    <p className="name">{props.name}</p>
                    <span>Управление <br/> версиями</span>
                </div>

                <div id="body-loading" className="body">
                    <p className="pre-title fadeout">Активная версия</p>
                    <div className="radio-boxes fadeout">
                        <div className="radio-box loading">
                            <div className="radio"><span></span></div>
                        </div>
                    </div>

                    <p className="pre-title fadeout">Другие версии</p>
                    <div className="radio-boxes fadeout">
                        <div className="radio-box loading">
                            <div className="radio"><span></span></div>
                        </div>
                        <div className="radio-box loading">
                            <div className="radio"><span></span></div>
                        </div>
                        <div className="radio-box loading">
                            <div className="radio"><span></span></div>
                        </div>
                    </div>
                </div>

                <div id="body" className="body" style={{display:'none'}}>

                    <p className="pre-title fadeout">Активная версия</p>
                    <div id="current-version" className="radio-boxes fadeout">
                        <div id="current" className="radio-box active" onClick={() => SelectVersion("current")}>
                            <p>1.0.15 | Загружена 09.04.2023 в 12:12 <p>[23.11MB]</p></p>
                            <div className="radio"><span></span></div>
                        </div>
                    </div>

                    <p className="pre-title fadeout">Другие версии</p>
                    <div id="versions" className="radio-boxes fadeout">
                        <div id="1.0.1" className="radio-box" onClick={() => SelectVersion("1.0.12")}>
                            <p>1.0.1 | Загружено 07.04.2023 в 22:18 <p>[21.19MB]</p></p>
                            <div className="radio"><span></span></div>
                        </div>
                    </div>
                    <div className="go-up fadeout">
                        <p id="uploadnew-versions-button" className="btn black clickable" style={{background:'#FF9B29', margin:'5px'}}>Загрузить новую</p>
                        <p id="release-versions-button" className="btn black clickable" style={{background:'#3DB37A', margin:'5px'}}>Отправить в релиз</p>
                        <p id="delete-versions-button" className="btn black clickable" style={{background:'#EA384D', margin:'5px'}}>Удалить с сервера</p>
                    </div>
                    <div className="go-up fadeout">
                        <p id="files-button" className="btn black clickable" style={{background:'#000', margin:'5px', width:'100%'}}>Дополнительные файлы</p>
                    </div>
                </div>

                <div id="upload-body" className="body" style={{display:'none'}}>

                    <p className="pre-title fadeout">Загрузка новой версии</p>
                    <div className="radio-boxes fadeout">
                        <div className="radio-box">
                            <input id="upload-file" type="file" onChange={handleFileChange}/>
                        </div>
                        <div id="upload-use-vmprotect" className="radio-box">
                            <p>Использовать VMProtect Professional 3.5.0</p>
                            <div className="radio"><span></span></div>
                        </div>
                    </div>

                    <input id="upload-identifier" type="text" hidden/>
                    <input id="upload-vmprotect" type="text" hidden/>

                    <div className="go-up fadeout">
                        <input id="upload-version" type="text" maxLength='10' placeholder="Введите название версии (12.34.56)"/>
                        <p id="upload-versions-button" className="btn black clickable" style={{background:'#3DB37A', margin:'5px'}} onClick={UploadVersion}>Загрузить</p>
                        <p id="upload-versions-button2" className="btn black clickable" style={{background:'#3DB37A', margin:'5px', display:"none"}}><img src={loading_image} width='80'/></p>
                        <p id="goback-versions-button" className="btn black clickable" style={{background:'#EA384D', margin:'5px'}}>Назад</p>
                    </div>
                </div>

                <div id="files-body-loading" className="body" style={{display:'none'}}>
                    
                    <p className="pre-title fadeout">Дополнительные файлы</p>
                    <div className="radio-boxes fadeout">
                        <div className="radio-box loading">
                            <div className="radio"><span></span></div>
                        </div>
                        <div className="radio-box loading">
                            <div className="radio"><span></span></div>
                        </div>
                        <div className="radio-box loading">
                            <div className="radio"><span></span></div>
                        </div>
                    </div>
                </div>

                <div id="files-body" className="body" style={{display:'none'}}>

                    <p className="pre-title fadeout">Дополнительные файлы</p>
                    <div id="files" className="radio-boxes fadeout">
                        <div id="1.0.1" className="radio-box" onClick={() => SelectVersion("1.0.12")}>
                            <p>1.0.1 | Загружено 07.04.2023 в 22:18 <p>[21.19MB]</p></p>
                            <div className="radio"><span></span></div>
                        </div>
                    </div>
                    <div className="go-up fadeout">
                        <p id="uploadnew-files-button" className="btn black clickable" style={{background:'#FF9B29', margin:'5px', width:'50%'}}>Загрузить новый файл</p>
                        <p id="delete-files-button" className="btn black clickable" style={{background:'#EA384D', margin:'5px', width:'50%'}}>Удалить с сервера</p>
                    </div>
                    <div className="go-up fadeout">
                        <p id="files-back-button" className="btn black clickable" style={{background:'#000', margin:'5px', width:'100%'}}>Назад</p>
                    </div>
                </div>

                <div id="files-upload-body" className="body" style={{display:'none'}}>

                    <p className="pre-title fadeout">Загрузка нового файла</p>
                    <div className="radio-boxes fadeout">
                        <div className="radio-box">
                            <input id="upload-file-other" type="file" onChange={handleFileOtherChange}/>
                        </div>
                    </div>

                    <input id="upload-other-identifier" type="text" hidden/>

                    <div className="go-up fadeout">
                        <input id="upload-other" type="text" maxLength='32' placeholder="Введите название файла (test.txt)"/>
                        <p id="upload-files-button" className="btn black clickable" style={{background:'#3DB37A', margin:'5px'}} onClick={UploadFile}>Загрузить</p>
                        <p id="upload-files-button2" className="btn black clickable" style={{background:'#3DB37A', margin:'5px', display:"none"}}><img src={loading_image} width='80'/></p>
                        <p id="goback-files-button" className="btn black clickable" style={{background:'#EA384D', margin:'5px'}}>Назад</p>
                    </div>
                </div>

            </div>
        </div>

        <div className="status-view inactive" onClick={() => Show()}><i className="icon upload"></i></div>
    </>
    );
};
export default AccountCoder_Upload;