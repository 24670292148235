import React from 'react';

let last_id = 0;

function CreatePopup(type, text) {
    last_id++;

    let popups = document.getElementById("popups");
    if (popups)
    {
        let count = popups.childElementCount;
        if (count > 2)
            document.getElementById("popup" + (last_id - 3)).remove();

        let notification = document.createElement("div");
        notification.className = "notification-block " + type;
        notification.id = "popup" + last_id;

        let header = document.createElement("div");
        header.className = "header-note";

        let icon = document.createElement("div");
        icon.className = "icon-note";

        let icon2 = document.createElement("i");
        icon2.className = "icon";

        let p = document.createElement("p");
        p.innerHTML = text;

        // if (text.length > 50)
        //     notification.className += " bigger";
        //
        // if (text.length > 50)
        // {
        //     icon.innerHTML = type;
        // }

        icon.append(icon2);

        header.append(icon);

        notification.append(header);
        notification.append(p);
        popups.append(notification);
        
        setTimeout(function () {
            notification.className += " show";
        }, 100);
        setTimeout(function () {
            notification.remove();
        }, 5000);
    }
}

export function ShowError(text) {
    CreatePopup("warning", text);
}
export function ShowSuccess(text) {
    CreatePopup("success", text);
}
export function ShowInfo(text) {
    CreatePopup("info", text);
}

const Popups = () => {
    return (
        <div id="popups" className="notification-wrapper-block"></div>
    );
};
export default Popups;