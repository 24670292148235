import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import {ShowError} from './Popups';
import empty_image from "../../images/empty.png"

const Index_Popular = () => {
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const [GamesArray, SetGamesArray] = React.useState([]);

    function decrypt(encryptedData) {
        const encryptedBytes = atob(encryptedData);
        const keyBytes = unescape(encodeURIComponent("MyEncryptionKey123"));

        const decryptedBytes = new Uint8Array(encryptedBytes.length);

        for (let i = 0; i < encryptedBytes.length; i++) {
            const encryptedByte = encryptedBytes.charCodeAt(i);
            const keyByte = keyBytes.charCodeAt(i % keyBytes.length);

            // Пропускаем символы, не соответствующие ASCII
            if (encryptedByte > 255 || keyByte > 255) {
                decryptedBytes[i] = encryptedByte;
            } else {
                decryptedBytes[i] = encryptedByte ^ keyByte;
            }
        }

        const decoder = new TextDecoder('utf-8');
        const decryptedString = decoder.decode(decryptedBytes);

        return JSON.parse(decryptedString);
    }

    useEffect(()=> {
        axios.post('https://api.0xcheats.net/Games', qs.stringify({ 'active': "true", 'count': "4" }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);
            
            if (json.success === "true")
            {
                SetGamesArray(json.hacks);
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            
        });
    }, [])

    function FactPrice(amount, discount) {
        return amount - (amount/100*discount);
    }
    function Games() {
        return GamesArray.map((game) =>
            <div key={game.identifier} className="product-wrapper fadeout">
                <div className="product">
                    { game.discount > 0 ? <p className="sale">{"-" + game.discount + "%"}</p> : "" }
                    <Link to={"/games/" + game.identifier}><img src={"https://api.0xcheats.net/Images/Games/" + game.identifier +"/logo.jpg"} alt="" draggable="false"/></Link>
                    <div className="info">
                        <Link to={"/games/" + game.identifier} className="name">{game.name}</Link>
                        <div className="hl"></div>
                        <div className="price">
                            { game.status === 1 ? <span>{t('popular_price')}:</span> : <span> </span> }
                            
                            { game.status === -1 ? <Link to={"/games/" + game.identifier} className="btn gray2">{t('popular_status_unavailable')}</Link> : "" }
                            { game.status === 0 ? <Link to={"/games/" + game.identifier} className="btn gray2">{t('popular_status_updating')}</Link> : "" }
                            { game.status === 1 ? <Link to={"/games/" + game.identifier} className="btn black">{t('popular_from')} {FactPrice(game.price, game.discount)} ₽</Link> : "" }
                            { game.status === 2 ? <Link to={"/games/" + game.identifier} className="btn gray2">{t('popular_status_testing')}</Link> : "" }
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    if (GamesArray.length > 0) {
        return (
        <>
            <section id="popular">
                <div className="container">
                    <div className="content">
                        <p className="title">{t('popular_title')}</p>
                    </div>
                </div>
            </section>

            <section id="products" className="fadeout">
                <div className="container">
                    <div className="products">
                        <Games/>
                    </div>
                </div>
            </section>
        </>
        );
    }
    else {
        return (
        <>
            <section id="popular">
                <div className="container">
                    <div className="content">
                        <p className="title">{t('popular_title')}</p>
                    </div>
                </div>
            </section>

            <section id="products" className="fadeout">
                <div className="container">
                    <div className="products">

                        <div className="product-wrapper">
                            <div className="product">
                                <a href=""><img src={empty_image} className="loading" alt=""/></a>
                                <div className="info">
                                    <a href="" className="name loading">Software Name</a>
                                    <div className="hl"></div>
                                    <div className="price loading">
                                        <br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="product-wrapper">
                            <div className="product">
                                <a href=""><img src={empty_image} className="loading" alt=""/></a>
                                <div className="info">
                                    <a href="" className="name loading">Software Name</a>
                                    <div className="hl"></div>
                                    <div className="price loading">
                                        <br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="product-wrapper">
                            <div className="product">
                                <a href=""><img src={empty_image} className="loading" alt=""/></a>
                                <div className="info">
                                    <a href="" className="name loading">Software Name</a>
                                    <div className="hl"></div>
                                    <div className="price loading">
                                        <br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="product-wrapper">
                            <div className="product">
                                <a href=""><img src={empty_image} className="loading" alt=""/></a>
                                <div className="info">
                                    <a href="" className="name loading">Software Name</a>
                                    <div className="hl"></div>
                                    <div className="price loading">
                                        <br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
        );
    }
};
export default Index_Popular;