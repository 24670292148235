import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import Header from './Header';
import Footer from './Footer';

import Popups, {ShowError} from './Modules/Popups';

import empty_image from "../images/empty.png"


const Games = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const [GamesArray, SetGamesArray] = React.useState([]);

    function decrypt(encryptedData) {
        const encryptedBytes = atob(encryptedData);
        const keyBytes = unescape(encodeURIComponent("MyEncryptionKey123"));

        const decryptedBytes = new Uint8Array(encryptedBytes.length);

        for (let i = 0; i < encryptedBytes.length; i++) {
            const encryptedByte = encryptedBytes.charCodeAt(i);
            const keyByte = keyBytes.charCodeAt(i % keyBytes.length);

            // Пропускаем символы, не соответствующие ASCII
            if (encryptedByte > 255 || keyByte > 255) {
                decryptedBytes[i] = encryptedByte;
            } else {
                decryptedBytes[i] = encryptedByte ^ keyByte;
            }
        }

        const decoder = new TextDecoder('utf-8');
        const decryptedString = decoder.decode(decryptedBytes);

        return JSON.parse(decryptedString);
    }
    function GetHwid() {
        var browser = navigator.hardwareConcurrency + "," + navigator.languages; //navigator.userAgent
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }

    function ActiveGames() {
        if (document.getElementById("activeGames")) {
            document.getElementById("activeGames").classList.add("active");
            document.getElementById("disabledGames").classList.remove("active");
        }
        SetGamesArray([]);

        axios.post('https://api.0xcheats.net/Games', qs.stringify({ 'active': "true", 'token': cookies.token, 'ahwid': GetHwid() }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);
            
            if (json.success == "true")
            {
                if (document.getElementById("activeGames"))
                {
                    document.getElementById("activeGames").classList.add("active");
                    document.getElementById("disabledGames").classList.remove("active");
                }

                SetGamesArray(json.hacks);
            }
            else if (json.failed != null)
            {
                ShowError(json.failed);
            }
        })
        .catch(function(error)
        {
            ShowError(error);
        })
        .finally(function()
        {
            
        });
    }
    function DisabledGames() {
        if (document.getElementById("activeGames")) {
            document.getElementById("activeGames").classList.remove("active");
            document.getElementById("disabledGames").classList.add("active");
        }
        SetGamesArray([]);

        axios.post('https://api.0xcheats.net/Games', qs.stringify({ 'active': "false", 'token': cookies.token, 'ahwid': GetHwid() }))
        .then(function(response)
        {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);
            
            if (json.success == "true")
            {
                if (document.getElementById("activeGames"))
                {
                    document.getElementById("activeGames").classList.remove("active");
                    document.getElementById("disabledGames").classList.add("active");
                }

                SetGamesArray(json.hacks);
            }
            else if (json.failed != null)
            {
                ShowError(json.failed);
            }
        })
        .catch(function(error)
        {
            ShowError(error);
        })
        .finally(function()
        {
            
        });
    }

    useEffect(()=> {
        document.getElementById("_games").className = "active";
        ActiveGames();
    }, [])


    function FactPrice(amount, discount) {
        return amount - (amount/100*discount);
    }
    function Games() {
        return GamesArray.map((game) =>
            <div key={game.identifier} className="product-wrapper fadeout">
                <div className="product">
                    { game.discount > 0 ? <p className="sale">{"-" + game.discount + "%"}</p> : "" }
                    { game.stats.month > 0 ? <p className="stats">Статистика:<br/>{game.stats.week} Руб. / {game.stats.week2} Руб.<br/>{game.stats.month} Руб. / {game.stats.month2} Руб.</p> : "" }

                    <Link to={"/games/" + game.identifier}><img src={"https://api.0xcheats.net/Images/Games/" + game.identifier +"/logo.jpg"} alt="" draggable="false"/></Link>
                    <div className="info">
                        <Link to={"/games/" + game.identifier} className="name">{game.name}</Link>
                        <div className="hl"></div>
                        <div className="price">
                            { game.status === 1 ? <span>{t('games_price')}:</span> : <span> </span> }
                            
                            { game.status === -1 ? <Link to={"/games/" + game.identifier} className="btn gray2">{t('games_status_unavailable')}</Link> : "" }
                            { game.status === 0 ? <Link to={"/games/" + game.identifier} className="btn gray2">{t('games_status_updating')}</Link> : "" }
                            { game.status === 1 ? <Link to={"/games/" + game.identifier} className="btn black">{t('games_from')} {FactPrice(game.price, game.discount)} ₽</Link> : "" }
                            { game.status === 2 ? <Link to={"/games/" + game.identifier} className="btn gray2">{t('games_status_testing')}</Link> : "" }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (GamesArray.length > 0) {
        return (
        <>
            <Popups/>

            <Header/>

            <section id="products" className="fadeout">
                <div className="filter-sort">
                    <div className="container">
                        <div className="content">
                            <p className="pre-title">{t('games_title_1')} <span>{t('games_title_2')}</span></p>
                            <div className="actions">
                                <p id="activeGames" className="radio active" onClick={ActiveGames}>{t('games_active')}</p>
                                <p id="disabledGames" className="radio" onClick={DisabledGames}>{t('games_inactive')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="products">
                        <Games/>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
        );
    }
    else {
        return (
        <>
            <Popups/>

            <Header/>

            <section id="products" className="fadeout">
                <div className="filter-sort">
                    <div className="container">
                        <div className="content">
                            <p className="pre-title">{t('games_title_1')} <span>{t('games_title_2')}</span></p>
                            <div className="actions">
                                <p className="radio active">{t('games_active')}</p>
                                <p className="radio">{t('games_inactive')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="products">

                        <div className="product-wrapper">
                            <div className="product">
                                <a href=""><img src={empty_image} className="loading" alt=""/></a>
                                <div className="info">
                                    <a href="" className="name loading">Software Name</a>
                                    <div className="hl"></div>
                                    <div className="price loading">
                                        <br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="product-wrapper">
                            <div className="product">
                                <a href=""><img src={empty_image} className="loading" alt=""/></a>
                                <div className="info">
                                    <a href="" className="name loading">Software Name</a>
                                    <div className="hl"></div>
                                    <div className="price loading">
                                        <br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="product-wrapper">
                            <div className="product">
                                <a href=""><img src={empty_image} className="loading" alt=""/></a>
                                <div className="info">
                                    <a href="" className="name loading">Software Name</a>
                                    <div className="hl"></div>
                                    <div className="price loading">
                                        <br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="product-wrapper">
                            <div className="product">
                                <a href=""><img src={empty_image} className="loading" alt=""/></a>
                                <div className="info">
                                    <a href="" className="name loading">Software Name</a>
                                    <div className="hl"></div>
                                    <div className="price loading">
                                        <br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="product-wrapper">
                            <div className="product">
                                <a href=""><img src={empty_image} className="loading" alt=""/></a>
                                <div className="info">
                                    <a href="" className="name loading">Software Name</a>
                                    <div className="hl"></div>
                                    <div className="price loading">
                                        <br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="product-wrapper">
                            <div className="product">
                                <a href=""><img src={empty_image} className="loading" alt=""/></a>
                                <div className="info">
                                    <a href="" className="name loading">Software Name</a>
                                    <div className="hl"></div>
                                    <div className="price loading">
                                        <br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="product-wrapper">
                            <div className="product">
                                <a href=""><img src={empty_image} className="loading" alt=""/></a>
                                <div className="info">
                                    <a href="" className="name loading">Software Name</a>
                                    <div className="hl"></div>
                                    <div className="price loading">
                                        <br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="product-wrapper">
                            <div className="product">
                                <a href=""><img src={empty_image} className="loading" alt=""/></a>
                                <div className="info">
                                    <a href="" className="name loading">Software Name</a>
                                    <div className="hl"></div>
                                    <div className="price loading">
                                        <br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <Footer/>
        </>
        );
    }
};
export default Games;